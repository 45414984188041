/* eslint-disable @typescript-eslint/no-unused-vars */
import { MeetingHistoryResponse, UnderstandIntention } from 'apis/types/response';
import { ResultContent } from '../../ResultBody';
import { ActionResultFieldKey } from 'locale/actionConstant';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { Description, HrBar, IntentionItem, Title, Wrapper } from './UnderstandIntention.style';

// ActionResultFieldKey

type FieldKey = keyof UnderstandIntention;

export default function UnderstandIntentionContent(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as UnderstandIntention;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const keys = Object.keys(data);
  const wrappedData = keys.map((field) => ({
    name: ActionResultFieldKey[field]?.[selectLanguage],
    key: field,
    value: data[field as FieldKey],
  }));
  return (
    <Wrapper>
      {wrappedData.map((item, idx) => (
        <IntentionItem key={idx}>
          <Title>{item.name}</Title>
          <Description>{item.value}</Description>
          {/* {wrappedData.length - 1 !== idx && <HrBar />} */}
        </IntentionItem>
      ))}
    </Wrapper>
  );
}
