import Locales from 'locale/*';
import {
  EmptyText,
  MoreLabel,
  MoreWrapper,
  OneDataText,
  TableBody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  TableWrapper,
} from './ActionTable.style';
import RightArrow from 'assets/images/arrow-right-s-line.svg';
import { useRecoilState } from 'recoil';
import { tableModalData, tableModalState } from 'states/tableModalState';
import TableModal from 'components/TableModal/TableModal';
import TableModalContent from './TableModalContent';

interface ActionTableProps {
  /** 여기서 받은 데이터는 JSON.parse() 를 해야한다. */
  data: string;
  /** 10개 제한을 할지 유무 */
  hasLimit?: boolean;
}

export interface ParsedDataType {
  columns: string[];
  data: string[][];
  index: number[];
}

/** 테이블 데이터 */
export default function ActionTable(props: ActionTableProps) {
  const { data, hasLimit = true } = props;
  const parsedData: ParsedDataType = JSON.parse(data ?? '{}');
  const [show, setShow] = useRecoilState(tableModalState);
  const [recoilData, setRecoilData] = useRecoilState<string>(tableModalData);
  /** 데이터가 없을 경우에도 필드에 값이 있는 상태로 오기 때문에 아래와 같은로직으로 빈 데이터 임을 확인하도록 함. */
  const isEmptyData =
    parsedData?.columns?.[0] === '' &&
    parsedData?.index[0] === 0 &&
    Number(parsedData?.data?.[0]?.[0]) === 0;
  const onClickMoreBtn = (argData: ParsedDataType) => {
    // alert('작업중 입니다.');
    setShow(true);
    setRecoilData(JSON.stringify(argData));
  };

  const onlyOneData =
    parsedData?.columns?.[0] === '' &&
    parsedData?.index?.[0] === 0 &&
    parsedData?.data?.[0].length === 1 &&
    !!parsedData?.data?.[0]?.[0];

  if (isEmptyData) return <EmptyText>{Locales.EMPTY_DATA}</EmptyText>;

  if (onlyOneData) {
    const oneData = parsedData?.data[0][0];
    return <OneDataText>{oneData}</OneDataText>;
  }

  if (parsedData?.data?.length >= 1 && parsedData?.columns?.length >= 1) {
    const filteredData = parsedData.data.filter((_, idx) => idx < 10);
    const overDataSize = parsedData.data.length >= 10;

    const renderData = hasLimit ? filteredData : parsedData.data;

    return (
      <>
        <TableWrapper>
          <TableThead>
            {parsedData.columns.map((th, idx) => (
              <TableTh key={idx} scope='col'>
                {th}
              </TableTh>
            ))}
          </TableThead>
          <TableBody>
            {renderData.map((tr: string[] | number[], trIdx: number) => {
              return (
                <TableTr key={trIdx}>
                  {tr.map((td, tdIdx) => (
                    <TableTd key={tdIdx}>{td}</TableTd>
                  ))}
                </TableTr>
              );
            })}
          </TableBody>
        </TableWrapper>
        {hasLimit && overDataSize && (
          <MoreWrapper onClick={() => onClickMoreBtn(parsedData)}>
            <MoreLabel>{Locales.MORE_DATA}</MoreLabel>
            <img src={RightArrow} />
          </MoreWrapper>
        )}
        {hasLimit && show && (
          <TableModal show={show} setShow={setShow}>
            <TableModalContent />
          </TableModal>
        )}
      </>
    );
  }
  return <EmptyText>{Locales.EMPTY_DATA}</EmptyText>;
}
