import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const languageSessionKey = 'lanauge_session';
const languageKey = 'language';
const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: languageSessionKey,
  storage: sessionStorage,
});

export type LanguageType = 'en' | 'ko';

const defaultLanguage: LanguageType = navigator.language === 'ko-KR' ? 'ko' : 'en';
export const languageState = atom({
  key: languageKey,
  default: defaultLanguage,
  effects_UNSTABLE: [persistAtom],
});
