import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import { MessageDiv } from './Message.style';

export default function Message(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const data = result as any;

  return (
    <Wrapper>
      <MessageDiv>{data}</MessageDiv>
    </Wrapper>
  );
}
