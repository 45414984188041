import { atom } from 'recoil';

const tableModalDataKey = 'table-modal-data';
const tableModalStateKey = 'table-modal-state';

export const tableModalState = atom({
  key: tableModalStateKey,
  default: false,
});

export const tableModalData = atom({
  key: tableModalDataKey,
  default: '',
});
