import { GroupStageType, RoomStateResponse } from 'apis/types/response';
import {
  AgendaTitleWrapper,
  ParticipantsDiv,
  ParticipantsImg,
  ParticipantsWrapper,
} from './Participants.style';
import { useEffect, useState } from 'react';
import Locales from 'locale/*';
import { useTypingEffect } from 'hooks/useTypingEffect';

interface ParticipantsProps {
  roomInfoData: RoomStateResponse;
  hasStage: boolean;
  groupStageType: GroupStageType;
  prevStageDone: boolean;
}

export const participantShowDelay = 1000;

export const groupShowDelay = participantShowDelay + 500;

export default function Participants(props: ParticipantsProps) {
  const { roomInfoData, hasStage, groupStageType, prevStageDone } = props;
  const [participantShow, setParticipantShow] = useState(false);

  useEffect(() => {
    if (prevStageDone) {
      if (roomInfoData && hasStage) {
        setTimeout(() => {
          setParticipantShow(true);
        }, participantShowDelay);
      } else {
        setParticipantShow(false);
      }
    }
  }, [prevStageDone, roomInfoData && hasStage]);

  const stepMessage = () => {
    if (groupStageType === 'preparation') {
      const agendaTextList = roomInfoData?.agenda.split(' ');
      const maxLength = agendaTextList.length;

      const splitedAgenda = agendaTextList.filter((_, idx) => {
        return idx <= 10;
      });
      const modifiedText = splitedAgenda.join(' ');
      const addedText = splitedAgenda.length === maxLength ? '' : ' ...';
      const result = modifiedText + addedText;
      return Locales.PREPARE.replace(`{{agenda}}`, result);
    }
    if (groupStageType === 'discussion') return Locales.DISCUSSION;
    if (groupStageType === 'vote') return Locales.VOTE;
    return '';
  };

  const { effectedText: participantsText } = useTypingEffect({
    text: stepMessage(),
    enabled: true,
  });

  if (roomInfoData) {
    return (
      <ParticipantsDiv $show={participantShow}>
        <ParticipantsWrapper $width={(roomInfoData.participants.length - 1) * 46}>
          {roomInfoData.participants.map((item, idx) => {
            return (
              <ParticipantsImg $left={idx * 24} src={item.icon_path} alt='icon' key={item.name} />
            );
          })}
        </ParticipantsWrapper>
        <AgendaTitleWrapper>{participantsText}</AgendaTitleWrapper>
      </ParticipantsDiv>
    );
  }
  return null;
}
