export default {
  ID_PLCAEHOLER: `Enter Your ID`,
  PW_PLACEHOLER: `Enter Password`,
  LOGIN: 'Log In',
  LOGIN_ERROR: `Please try again later.`,
  TEXT_AREA_PLACEHOLDER: `Enter prompts here`,
  HELLO: `Welcome. How can I help you?`,
  SUB_HELLO: `Please let us know your thoughts.\nWith the agents' suggestion, we can make a better choice.`,
  LOGOUT_ALERT: `Would you like to log out?`,
  NEW_CHAT: `New Agenda`,
  ROOM_STATUS_WORKING: 'Working',
  ROOM_STATUS_DONE: 'Done',
  ROOM_STATUS_ERROR: 'Error',
  ERROR_TITLE: 'Please refresh',
  ERROR_DESC: `An unexpected error occured.\nPlease retry again after refreshing the page`,
  REFRESH: `Refresh`,
  LOADING_1: `Generating answers.`,
  LOADING_2: `Please wait a moment.`,
  LOADING_3: `It may take around 2 minutes.`,
  LOADING_FIXED_TEXT: `Analyzing answers`,
  STOP: 'STOP',
  THINKING: `Thinking about the response`,
  STEP_0: `Agenda Deliberation`,
  STEP_1: `Data Analysis`,
  STEP_2: `Discussion`,
  STEP_3: `Decision Making`,
  STEP_4: `Conclusion`,
  DISCUSSION: `Discuss our agenda based on data analysis.`,
  PREPARE: `Start data analysis regarding [{{agenda}}].`,
  VOTE: `Start voting on decision proposals`,
  WORKING_ACTION_NAME: `Working on '{{action}}'`,
  COMPLETED_ACTION_NAME: `'{{action}}' Done`,
  WRONG_DATA: `Wrong data`,
  VOTE_TITLE: `Decision Proposal`,
  VOTE_IDX: `Proposal {{idx}}`,
  AGENT: 'host',
  CONCLUSION_START_MESSAGE: `We drew a conclusion via a vote`,
  PROPOSER: `Proposer`,
  VOTER: `Voter`,
  ERROR: `A system error occured. If the issue persists, please consult the system administrator.\nError Code: {{errorCode}}`,
  RETRY: `Retry`,
  SEE_FULL: `View All`,
  NEXT_THINKING: `Thinking about the next response`,
  EN: 'English',
  KO: 'Korean',
  ANALYZING: `Analyzing`,
  PLEASE_TYPING_PROMPT: `Please enter prompts`,
  MORE_DATA: `More data`,
  TABLE_MODAL_TITLE: `Table`,
  NOTICE: `Notice`,
  PROCESS: `Process`,
  EMPTY_DATA: `No data retrieved`,
  CONCLUSION_SUMMARY: 'Summary',
  REASON: 'Reason',
  TYPING_MESSAGE: `Please typing message`,
  PROCESSING: `Currently Processing`,
  SAME_ROOM_ID: `It is the same as the current room ID.`,
  STOP_ACTION: `Analysis has been stopped.`,
  ROOM_CHANGE: `Change rooms.`,
  PASSWORD: `The password is incorrect.`,
};
