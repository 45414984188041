import { useEffect } from 'react';

/** 뒤로가기 기능 방지 */
export default function usePreventBack() {
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.go(1);
    };

    return () => {
      window.onpopstate = () => {};
    };
  }, [window.history.state]);
}
