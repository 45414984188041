import { useResetRecoilState } from 'recoil';
import { conversationCompleteState } from 'states/conversationCompleteState';
import {
  drawerState,
  meetingRoomIdState,
  meetingStateAtom,
  requestTimeStampAtom,
} from 'states/meetingState';
import { meetingHistoryMessageState, myMessageState } from 'states/myMessageState';

export default function useResetRecoil() {
  const resetRoomIdState = useResetRecoilState(meetingRoomIdState);
  const resetMeetingState = useResetRecoilState(meetingStateAtom);
  const resetMyMessageState = useResetRecoilState(myMessageState);
  const resetMeetingHistoryState = useResetRecoilState(meetingHistoryMessageState);
  const resetRequestTimeStamp = useResetRecoilState(requestTimeStampAtom);
  const resetConversationCompleteState = useResetRecoilState(conversationCompleteState);
  const resetDrawerState = useResetRecoilState(drawerState);
  return {
    resetRoomIdState,
    resetMeetingState,
    resetMyMessageState,
    resetMeetingHistoryState,
    resetRequestTimeStamp,
    resetConversationCompleteState,
    resetDrawerState,
  };
}
