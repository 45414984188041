import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const UserChatWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UserChatDiv = styled.div`
  display: flex;
  gap: 16px;
`;
export const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;
export const TitleTime = styled.span`
  ${typography.caption()}
  color: ${colors.gray400};
`;
export const TitleName = styled.span`
  ${typography.body(700)}
  color: ${colors.white};
`;

export const TextDiv = styled.div`
  border: 1px solid ${colors.lineDefault};
  padding: 16px;
  border-radius: 16px 4px 16px 16px;
  background-color: ${colors.gray700};
  color: ${colors.white};
  ${typography.body(400)}
`;

export const UserIcon = styled.img`
  width: 44px;
  height: 44px;
`;
