import useGetMeetingHistory from 'apis/useGetMeeintHistory';
import useGetMeetingState from 'apis/useGetMeetingState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meetingStateAtom } from 'states/meetingState';
import {
  calcMeetingHistoryEnabled,
  calcMeetingStateEnabled,
  conversationSortByState,
} from 'utils/utils';
import useMeetingStateFollower from '../Footer/hooks/useMeeingStateFollower';
import { meetingHistoryMessageState } from 'states/myMessageState';
import useRecomposeHistoryData from 'pages/MainPage/hooks/useRecomposeHistoryData';
import { MeetingHistoryResponse } from 'apis/types/response';
import { ChatWrapper } from './Chat.style';
import ChatingBox from './components/ChatingBox/ChatingBox';

import GroupingChat from './GroupingChat/GroupingChat';
import Locales from 'locale/*';
import AgentChat from './components/AgentChat/AgentChat';
import VoteChat from './GroupingChat/VoteChat';
import SideWindow from '../SideWindow/SideWindow';
import { ForwardedRef, RefObject, forwardRef, useEffect, useRef } from 'react';
import ConclusionChat from './GroupingChat/ConclusionChat';
import useIntersectionObserver from 'pages/MainPage/hooks/useIntersectionObserver';
import ClarificationChat from './components/ClarificationChat/ClarificationChat';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';
import useInitialMeetingState from 'pages/MainPage/hooks/useInitialMeetingState';

interface ChatProps {
  bodyRef: RefObject<HTMLDivElement>;
}

const Chat = (props: ChatProps) => {
  const { bodyRef } = props;
  const meetingState = useRecoilValue(meetingStateAtom);
  const clarificationRef = useRef<HTMLDivElement>(null);
  const preParationRef = useRef<HTMLDivElement>(null);
  const discussionRef = useRef<HTMLDivElement>(null);
  const voteRef = useRef<HTMLDivElement>(null);
  const conclusionRef = useRef<HTMLDivElement>(null);

  const enabled = calcMeetingStateEnabled(meetingState);

  const { data: meetingStateData } = useGetMeetingState(enabled);
  const { data: initialMeetingState } = useInitialMeetingState();

  useMeetingStateFollower(meetingStateData?.state);
  const isErrorState = meetingStateData?.state === 'error';
  const historyCallEnabled = calcMeetingHistoryEnabled(meetingState);
  // console.log(historyCallEnabled);
  const { data: meetingHistoryData } = useGetMeetingHistory(historyCallEnabled, isErrorState);
  useRecomposeHistoryData(meetingHistoryData);
  const meetingConversation: MeetingHistoryResponse[] = useRecoilValue(meetingHistoryMessageState);
  // console.log(meetingState);

  // const observerResult = useIntersectionObserver({
  //   preParationRef,
  //   conclusionRef,
  //   discussionRef,
  //   voteRef,
  //   clarificationRef,
  // });
  // console.log('meetingState: ', meetingState);
  // console.log('전체대화: ', meetingConversation);
  if (meetingState !== '' && meetingConversation.length >= 1) {
    const currentStage = meetingConversation[meetingConversation.length - 1].stage;
    const noneConversation = conversationSortByState(meetingConversation, [
      'none',
      'clarification',
    ]);
    const prepareConversation = conversationSortByState(meetingConversation, ['preparation']);

    const discussionConversation = conversationSortByState(meetingConversation, ['discussion']);
    // console.log('디스커션: ',discussionConversation)
    const voteConversation = conversationSortByState(meetingConversation, ['vote']);

    const conclusionConversation = conversationSortByState(meetingConversation, ['conclusion']);
    // console.log(noneConversation);

    return (
      <>
        <ChatWrapper>
          <ClarificationChat
            ref={clarificationRef}
            data={noneConversation}
            groupStageType='clarification'
            isCurrentStage={currentStage === 'clarification'}
            initMeetingState={initialMeetingState}
          />
          <GroupingChat
            ref={preParationRef}
            groupStageType='preparation'
            data={prepareConversation}
            stepText={Locales.STEP_1}
            bodyRef={bodyRef}
            isCurrentStage={currentStage === 'preparation'}
            initMeetingState={initialMeetingState}
          />
          <GroupingChat
            ref={discussionRef}
            groupStageType='discussion'
            data={discussionConversation}
            stepText={Locales.STEP_2}
            bodyRef={bodyRef}
            isCurrentStage={currentStage === 'discussion'}
            initMeetingState={initialMeetingState}
          />
          <VoteChat
            ref={voteRef}
            groupStageType='vote'
            data={voteConversation}
            stepText={Locales.STEP_3}
            bodyRef={bodyRef}
            isCurrentStage={currentStage === 'vote'}
            initMeetingState={initialMeetingState}
          />
          <ConclusionChat
            data={conclusionConversation}
            ref={conclusionRef}
            bodyRef={bodyRef}
            groupStageType='conclusion'
            isCurrentStage={currentStage === 'conclusion'}
          />

          {isErrorState && (
            <AgentChat
              text={Locales.ERROR.replace('{{errorCode}}', String(meetingStateData?.error_code))}
              isError
              name={'host'}
              time={new Date().toISOString()}
            />
          )}
        </ChatWrapper>
        <SideWindow
          currentStage={currentStage}
          clarificationRef={clarificationRef}
          preparationRef={preParationRef}
          disccusionRef={discussionRef}
          voteRef={voteRef}
          conclusionRef={conclusionRef}
          // observerResult={observerResult}
        />
      </>
    );
  } else return null;
};

export default Chat;
