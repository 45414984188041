import { LanguageType } from 'states/localeState';
import Locales from './ko';

interface ActionResultFieldKeyType {
  [key: string]: Record<LanguageType, string>;
}
export const ActionResultFieldKey: ActionResultFieldKeyType = {
  // * CreateStatementOfWork
  deliverables: {
    en: 'Deliverables',
    ko: '작업 명세서 예상 결과물',
  },
  difficulty: {
    en: 'Difficulty',
    ko: '작업 명세서 작성 난이도',
  },
  reason: {
    en: 'Reason',
    ko: '작업 유형 선정 근거',
  },
  goal: {
    en: 'Goal',
    ko: '작업 목표',
  },
  name: {
    en: 'Title',
    ko: '제목',
  },
  // * UnderstandIntention
  necessary_information: {
    en: 'Necessary Information',
    ko: '필요한 데이터 목록',
  },
  perspectives: {
    en: 'Perspectives',
    ko: '작업 목표 달성을 위한 접근법',
  },
  task_type: {
    en: 'Task Type',
    ko: '작업 유형',
  },
  // * ContentType2
  case: {
    en: 'Case',
    ko: '검색 결과',
  },
  query: {
    en: 'Query',
    ko: 'SQL 쿼리',
  },
  data: {
    en: 'Data',
    ko: '데이터 결과',
  },
  analysis_result: {
    en: 'Analysis',
    ko: '분석 결과',
  },
  data_source: { en: 'Data Source', ko: '데이터 소스' },
  is_ambiguous: {
    en: 'Is Ambiguous',
    ko: '모호함',
  },
  statements: {
    en: 'SQL Statements',
    ko: '명세서',
  },
  evidence: {
    en: 'Evidence',
    ko: '증거',
  },
  response: {
    en: 'Response',
    ko: '응답',
  },
  options: {
    en: 'Vote Result',
    ko: '투표 결과',
  },
  your_summarized_opinion: {
    en: 'Opinion Summary',
    ko: '요약된 의견',
  },
  confidence: {
    en: 'Confidence',
    ko: '정확도',
  },
  vote: {
    en: 'Vote',
    ko: '투표',
  },
  conclusion_start_message: {
    en: 'a vote was taken to reach a conclusion on the agenda.',
    ko: Locales.CONCLUSION_START_MESSAGE,
  },
  /** // TODO: 해당 부분 OntologyKnowledge.tsx 에 추가하기 */
  table_column_dict: {
    en: 'Retrieved DB Entities',
    ko: '검색된 DB 엔티티',
  },
};
