import Locales from 'locale/*';
import { StopWrapper } from './Stop.style';
import StopSvg from 'assets/images/stop.svg';
import useStopMeeting from 'apis/useStopMeeting';
import { useRecoilState } from 'recoil';
import { toastState } from 'states/toastState';
export default function Stop() {
  const { mutate } = useStopMeeting();
  const [_, setToast] = useRecoilState(toastState);
  const onClickStop = () => {
    setToast({
      status: 'normal',
      show: true,
      message: Locales.STOP_ACTION,
    });
    mutate();
  };
  return (
    <StopWrapper onClick={onClickStop}>
      <img src={StopSvg} />
      <span>{Locales.STOP}</span>
    </StopWrapper>
  );
}
