import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { meetingSessionState } from 'states/meetingState';
import apiRequest from '.';
import { ROOM } from './url';
import { StartMeetingResponse } from './types/response';
import { LanguageType, languageState } from 'states/localeState';

const fetcher = (session_id: string, lang: LanguageType) => {
  return apiRequest
    .get<StartMeetingResponse[]>(ROOM.LIST, {
      params: {
        session_id,
        lang,
      },
    })
    .then(({ data }) => data);
};

export default function useGetRoomList(drawerOpen: boolean) {
  const sessionId = useRecoilValue(meetingSessionState);
  const selectedLanguage = useRecoilValue<LanguageType>(languageState);
  return useQuery({
    queryKey: [sessionId],
    queryFn: () => fetcher(sessionId, selectedLanguage),
    enabled: !!sessionId && drawerOpen,
    throwOnError: true,
  });
}
