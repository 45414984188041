import { ToastStatus } from 'states/toastState';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

const width = 600;

export const ToastMessageWrapper = styled.div<{ $show: boolean; $status: ToastStatus }>`
  position: fixed;
  top: 56px;
  min-height: 50px;
  background-color: ${({ $status }) => ($status === 'normal' ? colors.buttonPrimary : 'black')};
  z-index: 1;
  left: 50%;
  padding: 15px;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: translate(-50%, ${({ $show }) => ($show ? '0px' : '-100px')});
  transition: all 0.4s ease-out;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export const ToastText = styled.div`
  ${typography.h4(400)}
`;
