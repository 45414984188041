/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { GroupStageType, MeetingHistoryResponse, MeetingStateResponse } from 'apis/types/response';
import useGetRoomInformation from 'apis/useGetRoomInformation';

import ArrowDown from 'assets/images/arrow-down-s-line.svg';

import {
  CenterDiv,
  FoldBtnImg,
  FoldButton,
  GroupingChatConversationSection,
} from './GroupingChat.style';

import { ForwardedRef, Fragment, RefObject, forwardRef, useEffect, useRef, useState } from 'react';
import Locales from 'locale/*';

import {
  PersonWrapper,
  VoteAgentOpinionWrapper,
  VoteContentText,
  VoteDataItem,
  VoteDataWrapper,
  VoteDesc,
  VoteImg,
  VoteItemIdx,
  VoteItemWrapper,
  VoteLabel,
  VoteName,
  VotePerson,
  VoteProposerDiv,
  VoteTitle,
} from './VoteChat.style';
import { HrBar } from './CompletedAction/components/UnderstandIntention/UnderstandIntention.style';
import { colors } from 'styles/colors';
import { CompletesType, GroupChatConversation, groupShowDelay } from './GroupingChat';
import Participants from 'components/Participants/Participants';
import { useRecoilState } from 'recoil';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';
import { checkAllFieldDone, findPrevStepField, makeFirstWordUppercase } from 'utils/utils';
import useCompleteConversationInit from 'pages/MainPage/hooks/useCompleteConversationInit';

interface GroupingChatProps {
  groupStageType: GroupStageType;
  data: MeetingHistoryResponse[];
  stepText: string;
  bodyRef: RefObject<HTMLDivElement>;
  isCurrentStage: boolean;
  initMeetingState?: MeetingStateResponse;
}

export const lottieStyle = { width: 24 };

const refetchInterval = 1000;
const VoteChat = forwardRef((props: GroupingChatProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { initMeetingState, groupStageType, stepText, data, bodyRef, isCurrentStage } = props;
  const { data: roomInfoData, refetch } = useGetRoomInformation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [groupShow, setGroupShow] = useState(false);
  const [fold, setFold] = useState(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const [conversationComplete] =
    useRecoilState<ConversationCompleteState>(conversationCompleteState);
  const prevFieldKey = findPrevStepField(groupStageType);

  const prevStageDone = checkAllFieldDone(conversationComplete[prevFieldKey]);
  useCompleteConversationInit(data, groupStageType, initMeetingState);

  const hasStage = data.filter((elem) => elem.stage === groupStageType).length >= 1;

  useEffect(() => {
    if (isCurrentStage && prevStageDone) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isCurrentStage, wrapperRef.current, prevStageDone]);

  useEffect(() => {
    if (prevStageDone) {
      if (roomInfoData && hasStage) {
        setTimeout(() => {
          setGroupShow(true);
        }, groupShowDelay);
      } else {
        setGroupShow(false);
      }
    }
  }, [prevStageDone, roomInfoData && hasStage]);

  const onClickFoldButton = () => {
    setFold((prev) => !prev);
  };

  /** vote 단계가 시작되면 roomInformation 을 다시 호출해서 데이터를 갱신한다. */
  useEffect(() => {
    /**
     * room stage 가 vote 일 때, roomInfoData 를 호출한다.
     * response 에 options 필드에 데이터가 있다.(1,2,3 안 등등.. 발언자(proposer)가 누구인지)
     * 이 데이터를 이용해 UI를 그린다.
     * 그리고 conclusion 단계가 되면 options 필드 내에 vote 필드에 동의자가 누구인지 보여진다.
     * 그 후, 인터벌 호출 중단하기.
     */
    if (hasStage) {
      timer.current = setInterval(refetch, refetchInterval);
    }

    if (timer.current && roomInfoData?.stage === 'conclusion') {
      clearInterval(timer.current);
    }
    return () => clearInterval(Number(timer.current));
  }, [timer.current, hasStage, roomInfoData]);

  if (prevStageDone && roomInfoData && hasStage) {
    return (
      <div ref={wrapperRef}>
        <Participants
          roomInfoData={roomInfoData}
          hasStage={hasStage}
          groupStageType={groupStageType}
          prevStageDone={prevStageDone}
        />

        <CenterDiv $hideBtn={!fold}>
          <FoldButton onClick={onClickFoldButton}>
            {stepText}
            <FoldBtnImg src={ArrowDown} />
          </FoldButton>
        </CenterDiv>

        <GroupingChatConversationSection $fold={fold} ref={ref} $show={groupShow}>
          <CenterDiv style={{ marginBottom: 24 }}>
            <FoldButton onClick={onClickFoldButton}>
              {stepText}
              <FoldBtnImg src={ArrowDown} $reverse />
            </FoldButton>
          </CenterDiv>
          <VoteItemWrapper>
            <VoteTitle>{Locales.VOTE_TITLE}</VoteTitle>
            <VoteDataWrapper>
              {roomInfoData.options?.map((item, idx) => {
                return (
                  <Fragment key={idx}>
                    <VoteDataItem>
                      <VoteItemIdx>
                        {Locales.VOTE_IDX.replace('{{idx}}', String(item.index))}
                      </VoteItemIdx>
                      <VoteDesc>
                        <VoteContentText>{item.answer}</VoteContentText>
                        <VoteProposerDiv>
                          <VoteLabel>{Locales.PROPOSER}</VoteLabel>
                          <PersonWrapper>
                            {item.proposers?.map((person, idx2) => {
                              const targetAgent = roomInfoData.participants.filter(
                                (elem) => elem.name === person?.toLocaleLowerCase(),
                              )[0];

                              return (
                                <VotePerson key={idx2}>
                                  <VoteImg src={String(targetAgent?.icon_path)} />
                                  <VoteName>{makeFirstWordUppercase(targetAgent?.name)}</VoteName>
                                </VotePerson>
                              );
                            })}
                          </PersonWrapper>
                        </VoteProposerDiv>
                        <VoteProposerDiv>
                          <VoteLabel>{Locales.VOTER}</VoteLabel>
                          <PersonWrapper>
                            {item.vote?.map((person, idx) => {
                              const targetAgent = roomInfoData.participants.filter(
                                (elem) => elem.name === person,
                              )[0];
                              return (
                                <VotePerson key={idx}>
                                  <VoteImg src={String(targetAgent?.icon_path)} />
                                  <VoteName>{makeFirstWordUppercase(person)}</VoteName>
                                </VotePerson>
                              );
                            })}
                          </PersonWrapper>
                        </VoteProposerDiv>
                      </VoteDesc>
                    </VoteDataItem>
                    {roomInfoData.options.length - 1 !== idx && (
                      <HrBar $dashed $color={colors.gray400} />
                    )}
                  </Fragment>
                );
              })}
            </VoteDataWrapper>
          </VoteItemWrapper>
          <VoteAgentOpinionWrapper>
            {data.map((item, index) => {
              const targetAgent = roomInfoData.participants.filter(
                (elem) => elem.name === item.agent_name,
              )[0];
              const completedActions = item.completed_actions?.filter(
                (elem) => elem.action_state === 'completed',
              );
              const agentOpinion = completedActions?.filter(
                (elem) => elem.action_type === 'message',
              )?.[0];

              const ignoreMessageType = item.completed_actions?.filter(
                (elem) => elem.action_type !== 'message',
              );
              return (
                <GroupChatConversation
                  key={item.group_id}
                  groupId={item.group_id}
                  item={item}
                  index={index}
                  agentOpinion={agentOpinion}
                  targetAgent={targetAgent}
                  ignoreMessageType={ignoreMessageType}
                  groupStageType={groupStageType}
                />
              );
            })}
          </VoteAgentOpinionWrapper>
        </GroupingChatConversationSection>
      </div>
    );
  }
  return null;
});

export default VoteChat;
