import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react';
import AgentChat from '../AgentChat/AgentChat';
import UserChat from '../UserChat/UserChat';
import { ChatingBoxWrapper } from './ChatingBox.style';
import { GroupStageType, MeetingHistoryResponse } from 'apis/types/response';
import Locales from 'locale/*';

interface ChatingBoxProps {
  item: MeetingHistoryResponse;
  groupStateType: GroupStageType;
  index: number;
}

interface ComponentSortFuncProps {
  name: string;
  text: string;
  time: string;
  index: number;
  isUserConversation: boolean;
  isAgentConversation: boolean;
  groupStateType: GroupStageType;
  groupId: string;
}

const ComponentSortFunc = memo((props: ComponentSortFuncProps) => {
  const {
    name,
    text,
    time,
    isAgentConversation,
    isUserConversation,
    index,
    groupStateType,
    groupId,
  } = props;
  if (isUserConversation)
    return (
      <UserChat
        name={name}
        text={text}
        time={time}
        groupStateType={groupStateType}
        groupId={groupId}
      />
    );
  if (isAgentConversation)
    return (
      <AgentChat
        name={name}
        text={text}
        time={time}
        index={index}
        groupStateType={groupStateType}
        groupId={groupId}
      />
    );
  return null;
});

const ChatingBox = (props: ChatingBoxProps) => {
  const { item, index, groupStateType } = props;
  const [text, setText] = useState('');
  /** 스테이지에 따라서 */
  useEffect(() => {
    if (item.stage === 'clarification') {
      const clarificationFeedback = String(
        item.completed_actions?.filter((elem) => typeof elem.result === 'string')[0]?.result ||
          Locales.THINKING,
      );
      setText(clarificationFeedback);
    }
  }, [item]);

  const isUserConversation = item.agent_type === 'user';
  const isAgentConversation = item.agent_type === 'agent';

  const propsText = isUserConversation
    ? String(item?.completed_actions?.[0]?.result ?? '')
    : text || '';

  return (
    <ChatingBoxWrapper>
      <ComponentSortFunc
        name={item.agent_name}
        text={propsText}
        time={item.request_timestamp}
        isAgentConversation={isAgentConversation}
        isUserConversation={isUserConversation}
        index={index}
        groupId={item.group_id}
        groupStateType={groupStateType}
      />
    </ChatingBoxWrapper>
  );
};

export default memo(ChatingBox);
