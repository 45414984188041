/* eslint-disable react-hooks/exhaustive-deps */
import { GroupStageType, MeetingHistoryResponse, MeetingStateResponse } from 'apis/types/response';
import ChatingBox from '../ChatingBox/ChatingBox';
import { ForwardedRef, Fragment, forwardRef, memo, useEffect, useState } from 'react';

import useCompleteConversationInit from 'pages/MainPage/hooks/useCompleteConversationInit';
import styled from 'styled-components';

interface ClarificationChatProps {
  data: MeetingHistoryResponse[];
  groupStageType: GroupStageType;
  isCurrentStage: boolean;
  initMeetingState?: MeetingStateResponse;
}

const MAX_CLARIFICATION = 6;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ClarificationChat = forwardRef(
  (props: ClarificationChatProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { data, groupStageType, initMeetingState } = props;
    const [filteredData, setFilteredData] = useState<MeetingHistoryResponse[]>([]);

    const agentData = data.filter((elem) => elem.agent_type === 'agent');
    useEffect(() => {
      if (data.length >= 1) {
        setFilteredData(agentData);
      }
    }, [data]);

    useCompleteConversationInit(filteredData, 'clarification', initMeetingState);
    return (
      <Wrapper ref={ref}>
        {data.map((item, idx) => {
          return <ChatingBox key={idx} index={idx} item={item} groupStateType={groupStageType} />;
        })}
      </Wrapper>
    );
  },
);

export default ClarificationChat;
