import styled from 'styled-components';
import { typography } from 'styles/typographies';

export const ConclusionIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-bottom: 10px; */
`;

export const SummaryReasonText = styled.div`
  ${typography.body(400)}
`;

export const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Reason = styled.div`
  ${typography.body(700)}
  margin-bottom: 10px;
`;
