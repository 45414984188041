import useSetLanguage from 'pages/MainPage/hooks/useSetLanguage';
import {
  H1,
  QuestionLeftSide,
  QuestionListItem,
  QuestionListWrapper,
  WelcomeMain,
  WelcomeSubText,
  WelcomeWrapper,
} from './Welcome.style';
import Locales from 'locale/*';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { questionState } from 'states/questinoState';
import { useEffect, useState } from 'react';

export type QuestionListType = {
  en: string;
  ko: string;
};

const MessageSvg = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM11 10H13V12H11V10ZM7 10H9V12H7V10ZM15 10H17V12H15V10Z'
        fill='#94A3B8'
      />
    </svg>
  );
};

const RightArrow = () => {
  return (
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.386364'
        y='0.386364'
        width='33.2273'
        height='33.2273'
        rx='16.6136'
        fill='#222538'
      />
      <rect
        x='0.386364'
        y='0.386364'
        width='33.2273'
        height='33.2273'
        rx='16.6136'
        stroke='#5A5F7E'
        strokeWidth='0.772727'
      />
      <path
        d='M17.9059 17.0005L14.0811 13.1757L15.1739 12.0829L20.0915 17.0005L15.1739 21.9181L14.0811 20.8253L17.9059 17.0005Z'
        fill='#8C9BAA'
      />
    </svg>
  );
};

const defaultQuestionList: QuestionListType[] = [
  {
    en: 'Check overall readiness of our organization.',
    ko: '조직의 전반적인 준비 상태를 점검해주세요.',
  },
  {
    en: 'How many M16A1 rifles we have?',
    ko: 'M16A1 소총이 몇개 있습니까?',
  },
  {
    en: 'If we have some extra funds, which roles should we recruit for in the military?',
    ko: '자금이 조금 남는다면 군에서 어떤 직책을 채용해야 할까?',
  },
  {
    en: 'Hello, How many personnel are non-deployable?',
    ko: '반갑습니다, 배치 불가 인원은 몇 명 있나요?',
  },
  {
    en: 'Please evaluate the combat capabilities of our unit.',
    ko: '우리부대의 전투 능력을 평가해 주세요.',
  },
];
export default function Welcome() {
  const [selectQuestion, setSelectQuestion] = useRecoilState(questionState);
  const language = useRecoilValue<LanguageType>(languageState);
  const [count, setCount] = useState(0);
  const onClickQuestion = (text: string) => {
    setSelectQuestion(text);
  };
  /** Locales 가 갱신되지 않아 render 를 한번더 시키기 위한 임시조치. */
  useEffect(() => setCount((prev) => prev + 1), []);

  useSetLanguage();

  return (
    <WelcomeMain>
      <WelcomeWrapper>
        <H1>{Locales.HELLO}</H1>
        <WelcomeSubText>{Locales.SUB_HELLO}</WelcomeSubText>
      </WelcomeWrapper>
      <QuestionListWrapper>
        {defaultQuestionList.map((question, idx) => {
          return (
            <QuestionListItem key={idx} onClick={() => onClickQuestion(question[language])}>
              <QuestionLeftSide>
                <MessageSvg />
                <span>{question[language]}</span>
              </QuestionLeftSide>
              <RightArrow />
            </QuestionListItem>
          );
        })}
      </QuestionListWrapper>
    </WelcomeMain>
  );
}
