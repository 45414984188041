import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { Description, Title, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import Locales, { ActionResultFieldKey } from 'locale/actionConstant';
import { LanguageType, languageState } from 'states/localeState';
import { Speaking as SpeakingType } from 'apis/types/response';
import { StateOfWorkItem } from '../CreateStatementOfWork/CreateStatementOfWork.style';

type FieldKey = keyof SpeakingType;

export default function Speaking(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as SpeakingType;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const keys = Object.keys(data);
  const wrappedData = keys.map((field) => ({
    name: ActionResultFieldKey[field]?.[selectLanguage],
    key: field,
    value: data[field as FieldKey],
  }));
  return (
    <Wrapper>
      {wrappedData.map((item, idx) => (
        <StateOfWorkItem key={idx}>
          <Title>{item.name}</Title>
          <Description>{item.value}</Description>
        </StateOfWorkItem>
      ))}
    </Wrapper>
  );
}
