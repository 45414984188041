import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number, enabled = true) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      const tick = () => {
        savedCallback.current && savedCallback.current();
      };
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, enabled]);
};
