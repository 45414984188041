import { Button } from 'components/Button/Button';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const HEADER_HEIGHT = 55;
export const CHAT_INPUT_AREA_HEIGHT = 174;
export const PAGE_WIDTH = 944;
export const TAB_HEIGHT = 44;
const MAXWIDTH = 1920;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
  padding-top: ${HEADER_HEIGHT}px;
  background-color: ${colors.bg};
  position: relative;
  overflow: hidden;
`;

export const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: ${MAXWIDTH}px; */
  height: ${HEADER_HEIGHT}px;
  background-color: ${colors.headerBg};
  border-bottom: 1px solid ${colors.lineDefault};
  top: 0px;
  z-index: 100;
  padding: 0px 16px;
`;

export const HeaderImg = styled.img`
  cursor: pointer;
`;

export const HeaderGroup = styled.div`
  display: flex;
  gap: 20px;
`;

export const LogButton = styled(Button)`
  position: absolute;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const Body = styled.div`
  width: 100%;
  max-width: ${PAGE_WIDTH}px;
  /* max-width: 1920px; */
  margin: 0 auto;
  /* height: 100vh; */
  padding-bottom: 160px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  width: ${PAGE_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  /* background-color: gray; */
  padding-bottom: 20px;
`;
