import { createPortal } from 'react-dom';
import { ModalWrapper } from './TableModal.style';
import { ReactElement, useEffect, useState } from 'react';

interface TableModalProps {
  children: ReactElement;
  show: boolean;
  setShow: (v: boolean) => void;
}
const modalClassName = 'table-modal';
export default function TableModal(props: TableModalProps) {
  const { children, setShow, show } = props;
  const [modalRoot, setModalRoot] = useState<HTMLElement>();
  const handleModalClick = (e: any) => {
    const { className } = e.target as Element;
    // console.log('클릭한 이름: ', className);
    // console.log('감싸고 있는 클래스이름: ', ModalWrapper.styledComponentId);
    const clickClassName = className.split(' ')[0];
    if (clickClassName === ModalWrapper.styledComponentId) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      const modalDiv = document.createElement('div');
      document.body.appendChild(modalDiv);

      modalDiv.classList.add(modalClassName);
      // modalDiv.addEventListener('click', handleModalClick);
      setModalRoot(modalDiv);
      return () => {
        document.body.removeChild(modalDiv);
      };
    }
  }, [show]);

  if (modalRoot)
    return createPortal(
      <ModalWrapper onClick={handleModalClick}>{children}</ModalWrapper>,
      modalRoot,
    );
  return null;
}
