import { useEffect, useState } from 'react';
import { useInterval } from './useInterval';
import Locales from 'locale/*';

export const useTypingEffect = ({
  text,
  disabled,
  delay,
  callback,
  enabled = true,
}: {
  text: string;
  disabled?: boolean;
  /** 타이핑 더하는 시간 */
  delay?: number;
  callback?: () => void;
  enabled?: boolean;
}) => {
  // console.log({ text });
  const [effectedText, setEffectedText] = useState('');
  const [count, setCount] = useState(0);

  const isCompleted =
    text !== Locales.THINKING &&
    text?.length !== 0 &&
    !!(effectedText.length === text?.length || disabled);

  useEffect(() => {
    setEffectedText('');
    setCount(0);
  }, [text]);

  useInterval(
    () => {
      if (count === text?.length || disabled) {
        return;
      }

      setEffectedText((prev) => {
        const newText = prev ? prev + text[count] : text[0];
        return newText;
      });

      setCount((prev) => {
        return prev + 1;
      });
      callback && callback();
    },
    delay || 30,
    enabled,
  );

  return { effectedText, isCompleted };
};
