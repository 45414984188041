import { useMutation } from '@tanstack/react-query';
import { MEETING } from './url';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { meetingRoomIdState, meetingSessionState, requestTimeStampAtom } from 'states/meetingState';
import apiRequest from '.';

const fetcher = (session_id: string, room_id: string, text: string) => {
  return apiRequest
    .post(MEETING.POST_CLARIFICATION_MESSAGE, null, {
      params: {
        session_id,
        room_id,
        text,
      },
    })
    .then(({ data }) => data);
};

export default function useClarificationMessage() {
  const sessionId = useRecoilValue(meetingSessionState);
  const roomId = useRecoilValue(meetingRoomIdState);
  return useMutation({
    mutationKey: [MEETING.POST_CLARIFICATION_MESSAGE, sessionId, roomId],
    mutationFn: (message: string) => fetcher(sessionId, roomId, message),
  });
}
