/* eslint-disable react-hooks/exhaustive-deps */
import Lottie from 'lottie-react';
import thinkLottie from 'assets/lottie/ing.json';
import CompleteLottie from 'assets/lottie/complete.json';
import AlertLottie from 'assets/lottie/alert.json';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import PencilSvg from 'assets/images/pencil.svg';

import {
  drawerState,
  meetingRoomIdState,
  meetingStateAtom,
  requestTimeStampAtom,
} from 'states/meetingState';
import {
  LottieWrapper,
  MakeNewMeeting,
  ParticipantIcon,
  ParticipantNum,
  RoomDrawerWrapper,
  RoomListItem,
  RoomListParticipant,
  RoomListText,
  StatusText,
  Title,
} from './RoomDrawer.style';
import Locales from 'locale/*';
import { calcRoomStatusToText } from 'utils/utils';
import useGetRoomList from 'apis/useGetRoomList';
import useRoomListTranser from './hooks/useRoomListTransfer';
import { meetingHistoryMessageState, myMessageState } from 'states/myMessageState';
import { conversationCompleteState } from 'states/conversationCompleteState';
import { RefObject, useEffect, useRef } from 'react';
import { ToastType, toastState } from 'states/toastState';
import useResetRecoil from 'hooks/useResetRecoil';

export default function RoomDrawer({ drawerRef }: { drawerRef: RefObject<HTMLImageElement> }) {
  const [isDrawerOpen, setDrawerOpen] = useRecoilState(drawerState);
  const [currentRoomId, setMeetingRoomId] = useRecoilState<number>(meetingRoomIdState);
  const resetRequestTimeStamp = useResetRecoilState(requestTimeStampAtom);
  const { resetRoomIdState, resetMeetingState, resetMyMessageState, resetMeetingHistoryState } =
    useResetRecoil();

  const setMeetingHistoryMessage = useSetRecoilState(meetingHistoryMessageState);
  const resetConversationCompleteState = useResetRecoilState(conversationCompleteState);
  const [_, setToast] = useRecoilState<ToastType>(toastState);

  const { data } = useGetRoomList(isDrawerOpen);
  const roomData = useRoomListTranser(data);
  const drawerWrapperRef = useRef<HTMLDivElement>(null);

  const reset = () => {
    resetMeetingState();
    resetMyMessageState();
    resetRequestTimeStamp();
    resetConversationCompleteState();
  };

  const onClickNewChat = () => {
    resetRoomIdState();
    reset();
    setDrawerOpen(false);
    setMeetingHistoryMessage([]);
  };

  const onClickRoom = (roomId: number) => {
    if (currentRoomId === roomId) {
      setToast({
        show: true,
        message: Locales.SAME_ROOM_ID,
        status: 'normal',
      });
      return;
    }
    if (currentRoomId !== roomId) {
      setToast({
        show: true,
        message: Locales.ROOM_CHANGE,
        status: 'normal',
      });
      reset();
      setMeetingRoomId(roomId);
      resetMeetingHistoryState();
    }
  };

  const handleWindowClick = (e: any) => {
    if (drawerRef.current && drawerWrapperRef.current) {
      const isInsideClick = drawerWrapperRef.current.contains(e.target);
      const isDrawerIconClick = drawerRef.current.contains(e.target);
      if (isDrawerIconClick) {
        if (!isDrawerOpen) {
          setDrawerOpen(false);
        } else {
          setDrawerOpen(true);
        }
        return;
      }
      if (isDrawerOpen && !isInsideClick) {
        setDrawerOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleWindowClick);
    return () => {
      document.removeEventListener('click', handleWindowClick);
    };
  }, [isDrawerOpen, drawerWrapperRef.current, drawerRef.current]);

  return (
    <RoomDrawerWrapper $isOpen={isDrawerOpen} ref={drawerWrapperRef}>
      <MakeNewMeeting onClick={onClickNewChat}>
        <span>{Locales.NEW_CHAT}</span>
        <img src={PencilSvg} />
      </MakeNewMeeting>

      {roomData
        ? roomData.map((item, idx) => {
            const lottieIcon =
              item.status === 'working' || item.status === 'init'
                ? thinkLottie
                : item.status === 'error'
                  ? AlertLottie
                  : CompleteLottie;
            return (
              <RoomListItem onClick={() => onClickRoom(item.roomId)} key={idx}>
                <RoomListText>
                  <Title>{item.agenda}</Title>
                  <StatusText>
                    <LottieWrapper>
                      <Lottie
                        animationData={lottieIcon}
                        loop={item.status === 'working' || item.status === 'init'}
                      />
                    </LottieWrapper>
                    {calcRoomStatusToText(item.status)}
                  </StatusText>
                </RoomListText>
                <RoomListParticipant>
                  {item.participants
                    .map((agent, idx) => {
                      const hideImg = idx === 2 && item.participants.length > 3;

                      if (hideImg) {
                        const n = item.participants.length - 2;
                        return (
                          <ParticipantNum key={idx} $idx={idx}>
                            {'+' + n}
                          </ParticipantNum>
                        );
                      } else {
                        return <ParticipantIcon $idx={idx} key={idx} src={agent.url} />;
                      }
                    })
                    .filter((_, idx) => idx <= 2)}
                </RoomListParticipant>
              </RoomListItem>
            );
          })
        : null}
    </RoomDrawerWrapper>
  );
}
