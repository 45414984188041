import { atom } from 'recoil';

const toastKey = 'toast';

export type ToastStatus = 'normal' | 'error' | 'success';

export interface ToastType {
  message: string;
  show: boolean;
  status: ToastStatus;
}

const defaultValue: ToastType = {
  message: '',
  show: false,
  status: 'normal',
};

export const toastState = atom({
  key: toastKey,
  default: defaultValue,
});
