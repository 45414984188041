import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const Wrapper = styled.div<{ $inline?: boolean }>`
  display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};
  flex-direction: column;
  gap: 20px;
`;

export const IntentionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div<{ $hideDot?: boolean }>`
  padding-left: ${({ $hideDot }) => ($hideDot ? '0px' : '10px')};
  color: ${colors.white};
  position: relative;
  ${typography.body(700)}
  &::before {
    display: ${({ $hideDot }) => ($hideDot ? 'none' : 'block')};
    content: '';
    position: absolute;
    left: 0px;
    top: 4px;
    border-radius: 4px;
    width: 3px;
    height: 15px;
    background-color: ${colors.buttonPrimary};
  }
`;

export const Description = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
`;

export const HrBar = styled.div<{ $dashed?: boolean; $color?: string }>`
  width: 100%;
  height: 1px;
  border: 1px ${({ $dashed }) => ($dashed ? 'dashed' : 'solid')} transparent;
  border-color: ${({ $color }) => ($color ? $color : colors.lineDefault)};
  margin: 5px 0px;
`;
