import { Button } from 'components/Button/Button';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const FillStyledButton = styled(Button)`
  border: none;
  border-radius: 8px;
  background-color: ${colors.primary};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.blue400};
  }

  &:active {
    background-color: ${colors.blue600};
  }

  &:disabled {
    color: ${colors.disabledText};
    background-color: ${colors.disabledBtn};
  }
`;
