import { PAGE_WIDTH } from 'pages/MainPage/MainPage.styles';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const PromptTextArea = styled.textarea`
  ${typography.body()}
  padding: 16px 60px 16px 16px;
  background-color: ${colors.input};
  border: none;
  resize: none;
  outline: none;
  width: ${PAGE_WIDTH}px;
  border: 1px solid ${colors.lineDefault};
  border-radius: 8px;
  transition: all 0.1s linear;
  color: ${colors.white};
  &::placeholder {
    color: #94a3b8;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  /* &:focus {
    border-color: ${colors.blue600};
  } */
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SvgLabel = styled.label`
  position: relative;
`;

export const SendBtnSvg = styled.svg`
  position: absolute;
  cursor: pointer;
  bottom: 10px;
  right: 10px;
`;
