import { HEADER_HEIGHT } from 'pages/MainPage/MainPage.styles';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

const BOTTOM = 80;
const BOTTOM_MARGIN = 80;

export const WelcomeMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT + BOTTOM + BOTTOM_MARGIN}px);
`;

export const H1 = styled.span`
  color: ${colors.gray700};
  ${typography.h1()}
  margin-bottom: 16px;
  color: ${colors.textDEfault};
`;

export const WelcomeSubText = styled.div`
  ${typography.h4()}
  color: ${colors.gray400};
  white-space: pre-wrap;
  text-align: center;
`;

export const WelcomeWrapper = styled.div`
  margin: 15vh 0px;
`;

export const QuestionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const QuestionListItem = styled.div`
  padding: 10px 15px;
  border: 1px solid ${colors.lineDefault};
  border-radius: 16px;
  background-color: ${colors.cardBg};
  color: ${colors.gray400};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: ${colors.hoverWhite};
    border-color: ${colors.hoverWhite};
    > div > svg > path {
      fill: ${colors.hoverWhite};
    }
    > svg > rect {
      stroke: ${colors.hoverWhite};
    }
    > svg > path {
      fill: ${colors.hoverWhite};
      /* > svg > rect  */
    }
  }
`;

export const QuestionLeftSide = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const LeftSvg = styled.img``;
export const RightSvg = styled.img``;
