/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { format, max, addHours } from 'date-fns';

import { GroupStageType, MeetingHistoryResponse, MeetingState } from 'apis/types/response';
import Locales from 'locale/*';
import { CompleteStateValueType } from 'states/conversationCompleteState';

export const calcRoomStatusToText = (status: MeetingState) => {
  switch (status) {
    case 'end': {
      return Locales.ROOM_STATUS_DONE;
    }
    case 'error': {
      return Locales.ROOM_STATUS_ERROR;
    }
    case 'working': {
      return Locales.ROOM_STATUS_WORKING;
    }
    default:
      return '';
  }
};

/**
 * @param d 선택값 날짜 데이터
 * @returns 타임존 계산된 ISOString 이 리턴됨.
 */
export const makeTimeData = (d?: Date) => {
  const date = new Date(d ?? '');
  const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  return isoDateTime;
};

export const calcMeetingStateEnabled = (state: MeetingState) => {
  /** end 거나 error가 아니면 실행한다. */
  return !(state === 'end' || state === 'error' || state === 'stop');
};
/** 미팅 상태가 init 이거나 working 이면 history를 호출한다. */
export const calcMeetingHistoryEnabled = (state: MeetingState) => {
  return state === 'init' || state === 'working' || state === 'end' || state === 'error';
};

export const getHourMinTime = (time: string) => {
  const current = new Date(time);
  const addedDate = addHours(current, 9);
  const newDateFormat = format(addedDate, 'HH:mm:ss');
  return newDateFormat;
};

export const findLastTimeData = (data: MeetingHistoryResponse[]) => {
  if (data.length >= 1) {
    try {
      const dates = data.map((item) => new Date(item.request_timestamp));
      const latestDate = max(dates);
      const result = format(latestDate, 'yyyy-MM-dd HH:mm:ss');
      return result;
    } catch (err) {
      return '';
    }
  } else return '';
};

export const conversationSortByState = (
  data: MeetingHistoryResponse[],
  states: GroupStageType[],
) => {
  return data.filter((item) => states.filter((state) => state === item.stage).length >= 1 && item);
};

export const modifySqlQueryForHightLight = (sql: string) => {
  return sql
    .replaceAll('WHERE', '\nWHERE')
    .replaceAll(`FROM`, '\nFROM')
    .replaceAll('INNER JOIN', '\nINNER JOIN')
    .replaceAll('OUTER JOIN', '\nOUTER JOIN');
};

export const findPrevStepField = (groupStage: GroupStageType) => {
  switch (groupStage) {
    case 'clarification': {
      return 'clarification';
    }
    case 'preparation': {
      return 'clarification';
    }
    case 'discussion': {
      return 'preparation';
    }
    case 'vote': {
      return 'discussion';
    }
    case 'conclusion': {
      return 'vote';
    }
    default: {
      return 'clarification';
    }
  }
};

export const checkAllFieldDone = (completeState: CompleteStateValueType[]) => {
  return completeState.length >= 1 && completeState.every((elem) => elem.complete);
};

export const makeConversationDefaultData = (
  data: MeetingHistoryResponse[],
  value: boolean,
): CompleteStateValueType[] => {
  return data.map((item) => ({ id: item.group_id, complete: value }));
};

export const conversationDataMerge = (
  prevData: CompleteStateValueType[],
  defaultData: CompleteStateValueType[],
) => {
  const result = [];
  result.push(...prevData);
  for (const newItem of defaultData) {
    const found = result.find((elem) => elem.id === newItem.id);
    if (!found) {
      result.push(newItem);
    }
  }
  return result;
};

export const makeConversationCompleteData = (
  completeStates: CompleteStateValueType[],
  groupId: string,
  type: 'success' | 'fail',
) => {
  return completeStates.map((item) =>
    item.id === groupId ? { ...item, complete: type === 'success' ? true : false } : item,
  );
};

export const makeFirstWordUppercase = (job: string) => {
  return job
    ?.split(' ')
    ?.map((word) => {
      return word.split('').reduce((result, c, index) => {
        if (index === 0) {
          return result + c.toUpperCase();
        } else {
          return result + c;
        }
      }, '');
    })
    ?.join(' ');
};
