import { FilledButton } from 'components/FilledButton/FilledButton';
import loginSrc from 'assets/images/login-bg.png';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.bg};
  height: 100vh;
  background-image: url(${loginSrc});
  background-size: cover;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  /* background-color: ${colors.white}; */
  border: 1px solid ${colors.lineDefault};
  border-radius: 16px;
  overflow: hidden;
  /* border-image-source: linear-gradient(
    284.74deg,
    rgba(203, 239, 255, 0.16) -4.08%,
    rgba(255, 255, 255, 0) 44.93%
  ); */
`;

export const LoginTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.headerBg};
  width: 100%;
  padding: 20px 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 80px;
  background-color: ${colors.cardBg};
  border-top: 1px solid ${colors.lineDefault};
  /* border: 1px solid ${colors.lineDefault}; */
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 40px; */
  gap: 9px;
`;

export const LoginButton = styled(FilledButton)`
  ${typography.h4()}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  margin-top: 40px;
`;
