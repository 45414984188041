import { useMutation } from '@tanstack/react-query';
import { MEETING } from './url';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meetingRoomIdState, meetingSessionState, meetingStateAtom } from 'states/meetingState';
import apiRequest from '.';
import { MeetingState } from './types/response';

const fetcher = (session_id: string, room_id: number) => {
  return apiRequest
    .post(MEETING.RESUME, null, {
      params: {
        session_id,
        room_id,
      },
    })
    .then(({ data }) => data);
};

export default function useResumeMetting() {
  const sessionId = useRecoilValue(meetingSessionState);
  const roomId = useRecoilValue(meetingRoomIdState);
  const [meetingState, setMeetingState] = useRecoilState<MeetingState>(meetingStateAtom);

  return useMutation({
    mutationKey: [MEETING.RESUME, sessionId, roomId],
    mutationFn: () => fetcher(sessionId, roomId),
    onSuccess: () => {
      setMeetingState('working');
    },
  });
}
