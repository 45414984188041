import Locales from 'locale/*';
import * as S from './ErrorPage.styles';
import refreshImage from 'assets/images/refresh.svg';

const ErrorPage = () => {
  return (
    <S.Wrap>
      <S.Text>{Locales.ERROR_TITLE}</S.Text>
      <S.SubText>{Locales.ERROR_DESC}</S.SubText>
      <S.RefreshButton onClick={() => window.location.reload()}>
        <img src={refreshImage} />
        {Locales.REFRESH}
      </S.RefreshButton>
    </S.Wrap>
  );
};

export default ErrorPage;
