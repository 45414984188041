import { useNavigate } from 'react-router-dom';
import * as S from './LoginPage.styles';
import { KeyboardEvent, useState } from 'react';
import { Input } from 'components/Input/Input';
import LogoSvg from 'assets/images/logo.png';
import { useSetRecoilState } from 'recoil';
import { loggedInUserState } from 'states/loggedInUserState';
import { isProd } from 'config/index';
import { SESSION_STORAGE_KEY } from 'constants/index';
import Locales from 'locale';
import { ROUTE } from 'constants/route';
import usePostLogin from 'apis/usePostLogin';

const LoginPage = () => {
  const navigate = useNavigate();
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [username, setUsername] = useState(isProd ? '' : 'qa');
  const [password, setPassword] = useState(isProd ? '' : 'genesislab');

  const { mutateAsync: postLoginMutateAsync } = usePostLogin();
  const isLoginButtonDisabled = !!(!username || !password);

  const handleLogin = async () => {
    const response = await postLoginMutateAsync({ username, password });

    if (response) {
      // await mutateAsync();
      navigate(ROUTE.MAIN, { replace: true });
      window.sessionStorage.setItem(
        SESSION_STORAGE_KEY.LOGGED_IN_USER,
        JSON.stringify({ username, session: response.session_id }),
      );
      setLoggedInUser({ username, session: response.session_id });
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isLoginButtonDisabled) {
      return;
    }
    e.code === 'Enter' && handleLogin();
  };

  return (
    <S.Page>
      <S.LoginBox>
        <S.LoginTitleWrap>
          <img src={LogoSvg} style={{ height: 27 }} />
        </S.LoginTitleWrap>
        <S.LoginForm>
          <S.InputContainer>
            <Input
              autoComplete='new-password'
              value={username}
              placeholder={Locales.ID_PLCAEHOLER}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleEnter}
            />
            <Input
              autoComplete='new-password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={Locales.PW_PLACEHOLER}
              type='password'
              onKeyDown={handleEnter}
            />
          </S.InputContainer>
          <S.LoginButton disabled={isLoginButtonDisabled} onClick={handleLogin}>
            {Locales.LOGIN}
          </S.LoginButton>
        </S.LoginForm>
      </S.LoginBox>
    </S.Page>
  );
};

export default LoginPage;
