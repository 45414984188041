import { setLocalization } from 'locale/*';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';

/** 유저가 선택한 언어에 따라서 Locales 를 수정함. */
export default function useSetLanguage() {
  const [language] = useRecoilState<LanguageType>(languageState);
  useEffect(() => {
    if (language === 'en') {
      setLocalization('en');
    }

    if (language === 'ko') {
      setLocalization('ko');
    }
  }, [language]);
}
