export default {
  ID_PLCAEHOLER: `ID를 입력하세요.`,
  PW_PLACEHOLER: `비밀번호를 입력하세요.`,
  LOGIN: '로그인',
  LOGIN_ERROR: `잠시후 다시 이용해 주세요.`,
  TEXT_AREA_PLACEHOLDER: `여기에 프롬프트를 입력하세요.`,
  HELLO: `안녕하세요. 무엇을 도와드릴까요?`,
  SUB_HELLO: `어떤 생각이 있으신지 알려주시기 바랍니다.\nAgent의 제안으로 더 나은 선택을 할 수 있습니다.`,
  LOGOUT_ALERT: `로그아웃 하시겠습니까?`,
  NEW_CHAT: `새로운 의제`,
  ROOM_STATUS_WORKING: '자료 조사중',
  ROOM_STATUS_DONE: '완료',
  ROOM_STATUS_ERROR: '에러',
  ERROR_TITLE: '새로고침 해주세요.',
  ERROR_DESC: `예상치 못한 오류가 발생했습니다.\n새로 고친 후 다시 시도해 주세요.`,
  REFRESH: `새로고침`,
  LOADING_1: `답변을 열심히 생성하고 있습니다.`,
  LOADING_2: `잠시만 기다려 주세요.`,
  LOADING_3: `답변은 최대 2분 정도 소요될 수 있습니다.`,
  LOADING_FIXED_TEXT: `답변을 분석하고 있어요`,
  STOP: 'STOP',
  THINKING: `답변 생각 중`,
  STEP_0: `의제 도출`,
  STEP_1: `데이터 분석`,
  STEP_2: `의제 논의`,
  STEP_3: `의사 결정`,
  STEP_4: `결론 도출`,
  DISCUSSION: `자료 조사 내용을 토대로 의제를 논의합니다.`,
  PREPARE: `[{{agenda}}] 에 대해 데이터 분석을 시작합니다.`,
  VOTE: `안건 내용에 대한 투표를 진행합니다.`,
  WORKING_ACTION_NAME: `{{action}} 작업중`,
  COMPLETED_ACTION_NAME: `{{action}} 완료`,
  WRONG_DATA: `잘못된 데이터 입니다.`,
  VOTE_TITLE: `의사 결정 안`,
  VOTE_IDX: `{{idx}}안`,
  AGENT: 'host',
  CONCLUSION_START_MESSAGE: `투표를 통해 안건에 대한 결론을 도출했습니다.`,
  PROPOSER: `발언자`,
  VOTER: `투표자`,
  ERROR: `시스템 에러가 발생했어요. 문제가 지속되면 관리자에게 문의하세요.\n에러 코드: {{errorCode}}`,
  RETRY: `재시도`,
  SEE_FULL: `전체보기`,
  NEXT_THINKING: `다음 작업 준비중`,
  EN: 'English',
  KO: '한국어',
  ANALYZING: `분석중 입니다.`,
  PLEASE_TYPING_PROMPT: `프롬프트를 입력해 주세요.`,
  MORE_DATA: `데이터 더보기`,
  TABLE_MODAL_TITLE: `테이블`,
  NOTICE: `알림`,
  PROCESS: `진행 프로세스`,
  EMPTY_DATA: `조회된 내역이 없습니다.`,
  CONCLUSION_SUMMARY: '결론 요약',
  REASON: '이유',
  TYPING_MESSAGE: `메세지를 입력해 주세요.`,
  PROCESSING: `현재 처리중 입니다`,
  SAME_ROOM_ID: `현재 룸 ID와 같습니다.`,
  STOP_ACTION: `분석을 중지했습니다.`,
  ROOM_CHANGE: `룸을 변경합니다.`,
  PASSWORD: `비밀번호가 틀렸습니다.`,
};
