import Lottie from 'lottie-react';
import Generating from 'assets/lottie/generating.json';
import Loading from 'assets/lottie/loading.json';
import { AgentActionDiv } from '../GroupingChat.style';
import { lottieStyle } from '../GroupingChat';
import { Actions } from 'apis/types/response';
import { CompletedActionSpan } from '../CompletedAction/CompletedAction.style';
import { WorkingActionWrapper } from './WorkingAction.style';
import { useRecoilState } from 'recoil';
import { languageState } from 'states/localeState';
import Locales from 'locale/*';
import { useTypingEffect } from 'hooks/useTypingEffect';

interface WorkingActionProps {
  action?: Actions;
  done?: boolean;
  isCompleted: boolean;
}

export default function WorkingAction(props: WorkingActionProps) {
  const { action, isCompleted } = props;
  const [language, setLanguage] = useRecoilState(languageState);
  const text = action
    ? Locales.WORKING_ACTION_NAME.replace(`{{action}}`, action?.action_name)
    : Locales.NEXT_THINKING;

  const { effectedText } = useTypingEffect({ text });

  if (isCompleted) {
    return null;
  }

  return (
    <AgentActionDiv $fitContent>
      <WorkingActionWrapper>
        <Lottie animationData={Loading} style={lottieStyle} />
        <CompletedActionSpan>{effectedText}</CompletedActionSpan>
      </WorkingActionWrapper>
    </AgentActionDiv>
  );
}
