/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect } from 'react';
import { SvgColor, activeColor } from '../Footer';

export default function useSvgColor(
  textValue: string,
  setSvgColor: Dispatch<SetStateAction<SvgColor>>,
) {
  useEffect(() => {
    if (textValue) {
      setSvgColor(activeColor);
    }
  }, [textValue]);
}
