import { useEffect, useState } from 'react';
import { ToastMessageWrapper } from './ToastMessage.style';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { ToastType, toastState } from 'states/toastState';
import NormalIcon from 'assets/images/feel-icon.svg';

const animationDelay = 300;
const textDisappearDelay = animationDelay + 300;
const disapperTime = 3000;

export default function ToastMessage() {
  const [toast, setToast] = useRecoilState<ToastType>(toastState);
  const reset = useResetRecoilState(toastState);
  const { show, message, status } = toast;
  const [showAnimation, setShowAnimation] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (message) {
      setText(message);
    } else {
      setTimeout(() => {
        setText('');
      }, textDisappearDelay);
    }
  }, [message]);

  useEffect(() => {
    if (show && message) {
      /** 등장 제어 */
      setTimeout(() => {
        setShowAnimation(true);
      }, animationDelay);
      /** 초기화 */
      setTimeout(() => {
        reset();
      }, disapperTime);
    } else {
      /** 사라짐 제어 */
      setTimeout(() => {
        setShowAnimation(false);
      }, animationDelay);
    }
  }, [show, message]);

  return (
    <ToastMessageWrapper $show={showAnimation} $status={status}>
      {status === 'normal' && <img src={NormalIcon} />}
      <div>{text}</div>
    </ToastMessageWrapper>
  );
}
