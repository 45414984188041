import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const TableWrapper = styled.table`
  border: 1px solid ${colors.gray400};
  border-radius: 10px;
  /* border-collapse: separate; */
  border-collapse: collapse;
`;

export const TableThead = styled.thead`
  background-color: ${colors.tableBg};
  border-radius: 5px;
  /* padding: 2px 10px; */
`;

export const TableTr = styled.tr`
  padding: 4px 10px;
`;

export const TableTh = styled.th`
  padding: 2px 10px;
  /* border-radius: 2px 4px 5px 6px; */
  border: 1px solid ${colors.gray400};
`;

/** TableData */
export const TableTd = styled.td`
  padding: 4px 10px;
  border: 1px solid ${colors.gray400};
  ${typography.body(400)}
`;

export const TableBody = styled.tbody``;

export const MoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

export const MoreLabel = styled.div`
  color: ${colors.white};
  ${typography.body(400)}
`;

export const EmptyText = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
`;

export const OneDataText = styled.div`
  ${typography.body(700)}
  color: ${colors.white};
`;
