import { CreateStatementOfWork } from 'apis/types/response';
import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { ActionResultFieldKey } from 'locale/actionConstant';
import {
  Description,
  HrBar,
  Title,
  Wrapper,
} from '../UnderstandIntention/UnderstandIntention.style';
import {
  SowDeliverablesItem,
  SowTableDeliverablesWrapper,
  StateOfWorkItem,
} from './CreateStatementOfWork.style';

type FieldKey = keyof CreateStatementOfWork;

export default function CreateStatementOfWorkContent(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as CreateStatementOfWork;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const keys = Object.keys(data);
  const wrappedData = keys.map((field) => ({
    name: ActionResultFieldKey[field]?.[selectLanguage],
    key: field,
    value: data[field as FieldKey],
  }));

  return (
    <Wrapper>
      {wrappedData.map((item, idx) => (
        <StateOfWorkItem key={idx}>
          <Title>{item.name}</Title>
          <Description>
            {item.key === 'deliverables' ? (
              <SowTableDeliverablesWrapper>
                {data.deliverables.map((item, idx) => (
                  <SowDeliverablesItem key={idx}>{item}</SowDeliverablesItem>
                ))}
              </SowTableDeliverablesWrapper>
            ) : (
              item.value
            )}
          </Description>
          {/* {wrappedData.length - 1 !== idx && <HrBar />} */}
        </StateOfWorkItem>
      ))}
    </Wrapper>
  );
}
