import * as S from './MainPage.styles';
import logoPng from 'assets/images/logo.png';
import logoutSvg from 'assets/images/logout.svg';
import tabSvg from 'assets/images/tab.svg';
import { useEffect, useRef, useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { loggedInUserState } from 'states/loggedInUserState';
import Welcome from './components/Welcome/Welcome';
import Chat from './components/Chat/Chat';
import Footer from './components/Footer/Footer';
import Locales, { setLocalization } from 'locale/*';
import { SESSION_STORAGE_KEY } from 'constants/index';
import {
  defaultMeetingSessionId,
  defaultRoomId,
  drawerState,
  meetingRoomIdState,
  meetingSessionState,
} from 'states/meetingState';
import RoomDrawer from 'components/RoomDrawer/RoomDrawer';
import Slider from 'components/Slider/Slider';
import { LanguageType, languageState } from 'states/localeState';
import { colors } from 'styles/colors';
import usePreventBack from 'hooks/usePreventBack';
import useSetLanguage from './hooks/useSetLanguage';
import useResetRecoil from 'hooks/useResetRecoil';

const MainPage = () => {
  usePreventBack();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const isDrawerOpen = useRecoilValue(drawerState);
  const meetingSessionId = useRecoilValue(meetingSessionState);
  const meetingRoomId = useRecoilValue(meetingRoomIdState);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setDrawerOpen = useSetRecoilState(drawerState);
  const chatHistoryEndRef = useRef<HTMLDivElement>(null);
  const chatListRef = useRef<HTMLDivElement>(null);
  const draweIconRef = useRef<HTMLImageElement>(null);
  const [language, setLanguage] = useRecoilState<LanguageType>(languageState);
  const {
    resetConversationCompleteState,
    resetMeetingHistoryState,
    resetMeetingState,
    resetMyMessageState,
    resetRequestTimeStamp,
    resetRoomIdState,
    resetDrawerState,
  } = useResetRecoil();

  const reset = () => {
    resetConversationCompleteState();
    resetMeetingHistoryState();
    resetMeetingState();
    resetMyMessageState();
    resetRequestTimeStamp();
    resetRoomIdState();
    resetDrawerState();
  };

  useSetLanguage();

  const onChangeLanguage = () => {
    if (language === 'en') {
      setLanguage('ko');
      setLocalization('ko');
    }
    if (language === 'ko') {
      setLanguage('en');
      setLocalization('en');
    }
  };
  const isEmpty = meetingRoomId === defaultRoomId;
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyRef.current && isEmpty) {
      // bodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [bodyRef.current, isEmpty]);

  const scrollToBottom = (behavior: ScrollBehavior) => {
    if (!chatListRef.current) {
      return;
    }
    chatHistoryEndRef.current?.scrollIntoView({
      behavior: behavior,
    });
  };
  /** 임시값 */

  const onClickLogout = () => {
    const isConfirm = confirm(Locales.LOGOUT_ALERT);
    if (isConfirm) {
      reset();
      sessionStorage.removeItem(SESSION_STORAGE_KEY.LOGGED_IN_USER);
      setLoggedInUser(null);
    }
  };

  const onClickDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const languageLabel = language === 'en' ? Locales.EN : Locales.KO;

  return (
    <S.Page>
      <S.Header>
        <S.HeaderImg
          ref={draweIconRef}
          onClick={onClickDrawer}
          src={tabSvg}
          className='drawer-icon'
        />
        <img src={logoPng} style={{ height: 27 }} />
        <S.HeaderGroup>
          <Slider
            label={languageLabel}
            active={language === 'ko'}
            setActive={onChangeLanguage}
            inActiveColor={colors.green500}
          />
          <S.HeaderImg onClick={onClickLogout} src={logoutSvg} />
        </S.HeaderGroup>
      </S.Header>
      <S.Body ref={bodyRef}>
        <RoomDrawer drawerRef={draweIconRef} />
        {isEmpty ? <Welcome /> : <Chat bodyRef={bodyRef} />}
      </S.Body>
      <S.FooterWrapper>
        <Footer />
      </S.FooterWrapper>
    </S.Page>
  );
};

export default MainPage;
