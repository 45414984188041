import { MeetingState } from 'apis/types/response';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { meetingRoomIdState, meetingStateAtom } from 'states/meetingState';

/**
 *
 * @param state data.state
 * @description 미팅의 상태를 팔로업하여 계속 호출할지 멈출지 제어해준다.
 */
export default function useMeetingStateFollower(state?: MeetingState) {
  const setMeetingState = useSetRecoilState(meetingStateAtom);
  const roomId = useRecoilValue<number>(meetingRoomIdState);
  useEffect(() => {
    if (state) {
      if (state === 'end') {
        setTimeout(() => {
          setMeetingState(state);
        }, 1500);
      } else {
        setMeetingState(state);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, roomId]);
}
