import { SESSION_STORAGE_KEY } from 'constants/index';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const LOGGED_IN_USER_STATE_KEY = 'loggedInUserState';

interface LoggedInUser {
  username: string;
  session: string;
}
const storageLoggedInUser = window.sessionStorage.getItem(SESSION_STORAGE_KEY.LOGGED_IN_USER);

const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: LOGGED_IN_USER_STATE_KEY,
  storage: sessionStorage,
});

export const loggedInUserState = atom<LoggedInUser | null>({
  key: LOGGED_IN_USER_STATE_KEY,
  default: storageLoggedInUser ? JSON.parse(storageLoggedInUser) : null,
  effects_UNSTABLE: [persistAtom],
});
