import styled, { keyframes } from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

const slideDown = keyframes`
from {
transform: translateY(100px);

}
to{
  transform: translateY(0px);
}
`;

export const GroupingChatConversationSection = styled.section<{ $fold: boolean; $show?: boolean }>`
  border: ${({ $fold }) => ($fold ? `unset` : `1px solid ${colors.bubbleLine}`)};
  background-color: ${colors.bubbleBg};
  border-radius: 8px;
  padding: ${({ $fold }) => ($fold ? 'unset' : '24px')};
  height: ${({ $fold }) => ($fold ? '0px' : 'unset')};
  overflow: hidden;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation: ${slideDown};
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const AgendaTitleWrapper = styled.div`
  ${typography.body(700)}
  color:${colors.gray400};
`;

export const CenterDiv = styled.div<{ $hideBtn?: boolean }>`
  display: flex;
  /* display: ${({ $hideBtn }) => ($hideBtn ? 'none' : 'flex')}; */
  visibility: ${({ $hideBtn }) => ($hideBtn ? 'hidden' : 'visible')};

  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  opacity: ${({ $hideBtn }) => ($hideBtn ? 0 : 1)};
`;

export const FoldButton = styled.button`
  ${typography.body(700)}
  color: ${colors.white};
  min-width: 140px;
  padding: 10px 15px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${colors.buttonPrimary};
  border: none;
  cursor: pointer;
`;

export const ChatBox = styled.div`
  min-height: 90px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const AgentSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const AgentImg = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;

export const AgentJob = styled.div`
  ${typography.caption(400)}
  color: ${colors.white};
  width: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  -webkit-line-clamp: 2;
  text-align: center;
  line-height: 1.3;
  height: 35px;
`;

export const AgentConversationSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const AgentNameAndTimeDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

export const AgnetName = styled.div`
  color: ${colors.white};
  ${typography.body(700)}
`;

export const AgnetTime = styled.div`
  ${typography.caption(400)};
  color: ${colors.gray400};
`;

export const AgentOpinionDiv = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
`;

export const AgentNameLineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ConversationFoldDiv = styled.div``;

export const AgentActionDiv = styled.div<{ $fitContent?: boolean }>`
  padding: 16px;
  color: ${colors.white};
  background-color: ${colors.chatBg};
  border: 1px solid ${colors.bubbleLine};
  border-radius: 4px 16px 16px 16px;
  min-height: 56px;

  width: ${({ $fitContent }) => ($fitContent ? `fit-content` : `100%`)};
  max-width: 828px;
`;

export const FoldBtnImg = styled.img<{ $reverse?: boolean }>`
  transform: ${({ $reverse }) => ($reverse ? 'rotate(180deg)' : '')};
`;
