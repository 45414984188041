import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const StopWrapper = styled.div`
  border: 1px solid ${colors.lineDefault};
  border-radius: 8px;
  padding: 8px 16px;
  display: inline-flex;
  color: ${colors.white};
  ${typography.body(400)}
  cursor: pointer;
  background-color: ${colors.input};
  gap: 4px;
  margin: 0 auto;
`;
