import styled from 'styled-components';
import { AgentActionDiv } from '../GroupingChat.style';
import { typography } from 'styles/typographies';
import { colors } from 'styles/colors';

export const CompletedActionDiv = styled(AgentActionDiv)`
  transition: all 0.2s ease-out;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
`;

export const CompletedActionLottieAndSpanWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;

export const CompletedActionSpan = styled.span`
  ${typography.body(700)} // TODO: 열리게 되면 폰트 작아지게 ?
  color: ${colors.white};
`;

export const CompletedActionArrow = styled.img<{ $fold: boolean }>`
  display: flex;
  transition: all 0.2s ease-out;
  transform: ${({ $fold }) => `rotate(${$fold ? 0 : 180}deg)`};
  cursor: pointer;
`;

export const ActionResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CompletedActionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionResultBody = styled.div<{ $fold: boolean }>`
  /* transition: all 0.2s ease-out; */
  height: ${({ $fold }) => ($fold ? `0px` : 'fit-content')};
  overflow: hidden;
  padding-top: ${({ $fold }) => ($fold ? '0px' : '10px')};
  transition: all 0.2s ease-out;
`;
