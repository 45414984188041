import { OntologyKnowledgeMakeCases } from 'apis/types/response';
import { ResultContent } from '../../ResultBody';
import { Title, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import {
  TableBody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  TableWrapper,
} from '../Table/ActionTable.style';
import { ActionResultFieldKey } from 'locale/actionConstant';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';

export default function OntologyKnowledge(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const data = result as OntologyKnowledgeMakeCases;

  const tableKeys = Object.keys(data.table_column_dict);
  const tableColumnDict = Object.keys(data)[0];

  return (
    <Wrapper $inline>
      <Title $hideDot={false}>{ActionResultFieldKey[tableColumnDict][selectLanguage]}</Title>
      {tableKeys.map((table, tableIdx) => {
        return (
          <TableWrapper key={tableIdx}>
            <TableThead>
              <TableTh scope='col'>{table}</TableTh>
            </TableThead>
            <TableBody>
              {data.table_column_dict[table].map((tableData, tdIdx) => (
                <TableTr key={tdIdx}>
                  <TableTd>{tableData}</TableTd>
                </TableTr>
              ))}
            </TableBody>
          </TableWrapper>
        );
      })}
    </Wrapper>
  );
}
