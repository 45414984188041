import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const RoomDrawerWrapper = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  height: 100vh;
  width: 400px;
  left: ${({ $isOpen }) => ($isOpen ? '0px' : '-400px')};
  background-color: ${colors.cardBg};
  transition: all 0.25s ease;
  border-right: 1px solid ${colors.lineDefault};
  z-index: 2;
  overflow-y: auto;
  padding-bottom: 100px;
`;

export const MakeNewMeeting = styled.div`
  margin: 16px;
  padding: 12px 16px;
  ${typography.body(700)}
  border: 1px solid ${colors.lineDefault};
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  color: ${colors.white};
  background-color: ${colors.input};
`;

export const RoomListItem = styled.div`
  border-bottom: 1px solid ${colors.lineDefault};
  /* height: 84px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
`;

export const RoomListText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  ${typography.body(700)}
  color: ${colors.white};
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* height: 50px; */
  width: 270px;
`;
export const StatusText = styled.span`
  color: ${colors.gray400};
  ${typography.caption()}
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

export const RoomListParticipant = styled.div`
  position: relative;
  height: 44px;
  width: 92px;
`;

export const ParticipantIcon = styled.img<{ $idx: number }>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${colors.gray200};
  position: absolute;
  left: ${({ $idx }) => `${$idx * 24}px`};
`;

export const ParticipantNum = styled.div<{ $idx: number }>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${colors.gray200};
  position: absolute;
  left: ${({ $idx }) => `${$idx * 24}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue800};
  color: ${colors.white};
  ${typography.body(700)}
`;

export const LottieWrapper = styled.div`
  display: inline-flex;
  width: 24px;
  height: 24px;
`;
