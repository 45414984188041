import styled from 'styled-components';
import { colors } from 'styles/colors';
import { TitleDiv } from '../UserChat/UserChat.style';
import { typography } from 'styles/typographies';

export const AgentChatWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

export const AgentChatTextDiv = styled.div<{
  $isError?: boolean;
  $isFinal?: boolean;
  $widthLimit?: boolean;
}>`
  border: 1px solid
    ${({ $isError, $isFinal }) =>
      $isError
        ? `${colors.red400}`
        : $isFinal
          ? `${colors.buttonPrimary}`
          : `${colors.lineDefault}`};
  padding: 16px;
  border-radius: 4px 16px 16px 16px;
  background-color: ${({ $isFinal }) => ($isFinal ? colors.final : colors.gray700)};
  color: ${colors.white};
  display: flex;
  flex-direction: ${({ $isFinal }) => ($isFinal ? 'column' : 'row')};
  align-items: flex-start;
  white-space: pre-wrap;
  ${typography.body(400)}
  width: ${({ $isFinal, $widthLimit }) => ($isFinal && $widthLimit ? '450px' : 'unset')};
`;

export const ConClusionWrapper = styled.div<{
  $paddingBottom?: number;
  $marginBottom?: number;
  $column?: boolean;
}>`
  display: flex;
  gap: 10px;
  ${({ $column }) => ($column ? `flex-direction: column;` : `flex-direction: row;`)}
  ${typography.body(700)}
  color: ${colors.white};
  padding-bottom: ${({ $paddingBottom }) => ($paddingBottom ? `${$paddingBottom}px` : '10px')};
  width: 100%;
  border-bottom: 1px solid ${colors.buttonPrimary};
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? `${$marginBottom}px` : '10px')};
`;

export const AgentChatTitleDiv = styled(TitleDiv)`
  justify-content: flex-start;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  background-color: ${colors.red400};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${typography.caption(700)}
  line-height: 1.2;
`;

export const ErrorResumeButton = styled.div`
  display: flex;
  justify-content: center;
  min-width: 110px;
  align-items: center;
  gap: 10px;
  color: ${colors.white};
  border: 1px solid ${colors.lineDefault};
  border-radius: 50px;
  padding: 6px 16px;
  background-color: ${colors.input};
  position: absolute;
  bottom: 0;
  right: -120px;
  cursor: pointer;
`;

export const AgentChatTextWrapper = styled.div`
  position: relative;
`;
