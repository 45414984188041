import { getHourMinTime, makeConversationCompleteData } from 'utils/utils';
import {
  InfoDiv,
  TextDiv,
  TitleDiv,
  TitleName,
  TitleTime,
  UserChatDiv,
  UserChatWrapper,
  UserIcon,
} from './UserChat.style';
import UserIconSrc from 'assets/images/user.png';
import { GroupStageType } from 'apis/types/response';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';

export interface UserChatProps {
  name: string;
  time: string;
  text: string;
  groupStateType?: GroupStageType;
  groupId?: string;
}

const UserChat = (props: UserChatProps) => {
  const { name, text, time, groupId, groupStateType } = props;
  const [conversationComplete, setConversationComplete] =
    useRecoilState<ConversationCompleteState>(conversationCompleteState);

  useEffect(() => {
    if (groupId && groupStateType) {
      setConversationComplete({
        ...conversationComplete,
        [groupStateType]: makeConversationCompleteData(
          conversationComplete[groupStateType],
          groupId,
          'success',
        ),
      });
    }
  }, []);

  return (
    <UserChatWrapper>
      <UserChatDiv>
        <InfoDiv>
          <TitleDiv>
            <TitleTime>{getHourMinTime(time)}</TitleTime>
            <TitleName>{name}</TitleName>
          </TitleDiv>
          <TextDiv>{text}</TextDiv>
        </InfoDiv>
        <UserIcon src={UserIconSrc} />
      </UserChatDiv>
    </UserChatWrapper>
  );
};

export default UserChat;
