export const colors = {
  gray800: '#1E293B',
  gray700: '#334155',
  gray500: '#64748B',
  gray400: '#94A3B8',
  blue200: '#BFDBFE',
  green50: '#F0FDF4',
  red400: '#F87171',
  red50: '#FEF2F2',
  blue50: '#EFF6FF',
  green500: '#22C55E',
  gray50: '#F8FAFC',
  gray200: '#E2E8F0',
  blue600: '#2563EB',
  blue400: '#60A5FA',
  blue800: '#1E40AF',
  primary: '#3B82F6',
  white: '#FFFFFF',
  gray100: '#F1F5F9',
  orange400: '#FB923C',
  yellow400: '#FACC15',
  purple400: '#C084FC',
  lineDefault: `#5A5F7E`,
  bubbleBg: `#283856`,
  bubbleLine: `#3f5678`,
  bg: `#1D1E30`,
  headerBg: `#292C41`,
  cardBg: `#222538`,
  placeholder: `#59697B`,
  input: `#333750`,
  disabledBtn: `#556179`,
  disabledText: `#1B2744`,
  buttonPrimary: `#337AF7`,
  chatBg: `#3A4967`,
  textDEfault: `#8C9BAA`,
  final: `#25519E`,
  tableBg: `#465574`,
  VoteLabelBg: `#1D2B47`,
  hoverWhite: `#F8FAFC`,
} as const;
