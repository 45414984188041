import 'normalize.css';
import '/App.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import LoginPage from 'pages/LoginPage/LoginPage';
import MainPage from 'pages/MainPage/MainPage';
import { useRecoilValue } from 'recoil';
import { loggedInUserState } from 'states/loggedInUserState';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { ROUTE } from 'constants/route';
import ToastMessage from 'components/ToastMessage/ToastMessage';

function App() {
  const loggedInUser = useRecoilValue(loggedInUserState);

  const router = createBrowserRouter([
    {
      path: ROUTE.MAIN,
      element: loggedInUser ? <MainPage /> : <Navigate replace to={ROUTE.LOGIN} />,
      errorElement: <ErrorPage />,
    },
    {
      path: ROUTE.LOGIN,
      element: loggedInUser ? <Navigate replace to={ROUTE.MAIN} /> : <LoginPage />,
    },
  ]);

  return (
    <>
      <ToastMessage />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
