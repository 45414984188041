import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const VoteItemWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.chatBg};
`;

export const VoteTitle = styled.div`
  color: ${colors.white};
  ${typography.body(700)}
  margin-bottom: 10px;
`;

export const VoteDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const VoteDataItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const VoteItemIdx = styled.div`
  min-width: 90px;
  padding: 0px 10px;
  height: 22px;
  ${typography.caption(700)}
  color: ${colors.blue400};
  background-color: #000;
  border: 1px solid ${colors.blue400};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const VoteDesc = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VoteAgentOpinionWrapper = styled.div`
  padding: 24px 0px;
`;

export const VoteContentText = styled.div``;

export const VoteLabel = styled.div`
  background-color: ${colors.VoteLabelBg};
  color: ${colors.blue400};
  padding: 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.blue400};
  border-radius: 4px;
  height: 28px;
  ${typography.caption(400)}
`;

export const VoteProposerDiv = styled.div`
  display: flex;
  gap: 10px;
`;
export const PersonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const VotePerson = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
`;

export const VoteImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const VoteName = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
`;
