import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const ExecuteCaseItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CaseSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CaseDesc = styled.div`
  ${typography.body(400)}
  color: ${colors.white};
`;

export const CaseTitle = styled.div`
  ${typography.body(700)}
  color: ${colors.white};
`;
