import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const DefaultStyledButton = styled.button`
  ${typography.h4()}
  cursor: pointer;
  width: fit-content;
  border: none;
  padding: 0px;
  background-color: ${colors.white};

  &:disabled {
    cursor: not-allowed;
  }
`;
