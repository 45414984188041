import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ChatWrapper = styled.div`
  width: 100%;
  /* overflow: scroll; */
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ChatGroupDiv = styled.div`
  background-color: ${colors.bubbleBg};
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${colors.bubbleLine};
`;
