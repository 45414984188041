import { useQuery, useQueryClient } from '@tanstack/react-query';
import apiRequest from '.';
import { MEETING, ROOM } from './url';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { meetingRoomIdState, meetingSessionState, requestTimeStampAtom } from 'states/meetingState';
import { MeetingHistoryResponse, RoomStateResponse } from './types/response';
import { findLastTimeData } from 'utils/utils';
import dayjs from 'dayjs';
import { LanguageType, languageState } from 'states/localeState';

interface Params {
  session_id: string;
  room_id: number;
  last_timestamp?: string;
}

const fetcher = (
  sessionId: string,
  roomId: number,
  isRoomEnd: boolean,
  requestTimeStamp: string,
  setRequestTimeStamp: SetterOrUpdater<string>,
  language: LanguageType,
) => {
  const params = {
    session_id: sessionId,
    room_id: roomId,
    /** 종료된 룸의 정보를 가져올 때 전체 데이터를 조회하도록 한다 */
    last_timestamp: dayjs(requestTimeStamp).format('YYYY-MM-DDTHH:mm:ss'),
    lang: language,
  };

  const { last_timestamp, ...rest } = params;
  const newParmas: Params = { ...rest };
  if (!isRoomEnd && requestTimeStamp !== '') {
    newParmas.last_timestamp = last_timestamp;
  }

  return apiRequest
    .get<MeetingHistoryResponse[]>(MEETING.GET_HISTORY, {
      params: newParmas,
    })
    .then(({ data }) => {
      const latestRequestTimeStamp = findLastTimeData(data);
      const prevRequestTimeStamp = requestTimeStamp;

      const newRequestTimeStamp = latestRequestTimeStamp
        ? latestRequestTimeStamp
        : prevRequestTimeStamp;
      if (!!newRequestTimeStamp) setRequestTimeStamp(newRequestTimeStamp);
      return data;
    });
};

export default function useGetMeetingHistory(enabled: boolean, isError?: boolean) {
  const queryClient = useQueryClient();
  const sessionId = useRecoilValue(meetingSessionState);
  const roomId = useRecoilValue(meetingRoomIdState);
  const [requestTimeStamp, setRequestTimeStamp] = useRecoilState(requestTimeStampAtom);
  const selectedLanguage = useRecoilValue(languageState);
  const roomInformation = queryClient.getQueryData([
    ROOM.ROOM_INFO,
    sessionId,
    roomId,
  ]) as RoomStateResponse;

  const isRoomEnd = roomInformation?.state === 'end';
  return useQuery({
    queryFn: () =>
      fetcher(
        sessionId,
        roomId,
        isRoomEnd,
        requestTimeStamp,
        setRequestTimeStamp,
        selectedLanguage,
      ),
    queryKey: [MEETING.GET_HISTORY, sessionId, roomId, selectedLanguage],
    enabled: !!sessionId && !!roomId && enabled,
    refetchInterval: isRoomEnd || isError ? false : 1000,
  });
}
