import Lottie from 'lottie-react';
import {
  ActionResultWrapper,
  CompletedActionArrow,
  CompletedActionDiv,
  CompletedActionLottieAndSpanWrapper,
  CompletedActionSpan,
  CompletedActionTitle,
} from './CompletedAction.style';
import Complete from 'assets/lottie/complete.json';
import { lottieStyle } from '../GroupingChat';
import { Actions } from 'apis/types/response';
import ArrowBlueDown from 'assets/images/arrow-down-blue-s-line.png';
import { useState } from 'react';

import Locales from 'locale/*';
import ResultBody from './ResultBody';

interface CompletedActionProps {
  action: Actions;
}

export default function CompletedAction(props: CompletedActionProps) {
  const { action } = props;
  const [fold, setFold] = useState(true);

  const onClickCompletedAction = () => {
    setFold((prev) => !prev);
  };

  return (
    <CompletedActionDiv onClick={onClickCompletedAction}>
      <ActionResultWrapper>
        <CompletedActionTitle>
          <CompletedActionLottieAndSpanWrapper>
            <Lottie animationData={Complete} loop={false} style={lottieStyle} />
            <CompletedActionSpan>
              <span>{Locales.COMPLETED_ACTION_NAME.replace(`{{action}}`, action.action_name)}</span>
            </CompletedActionSpan>
          </CompletedActionLottieAndSpanWrapper>
          <CompletedActionArrow $fold={fold} src={ArrowBlueDown} />
        </CompletedActionTitle>
        <ResultBody fold={fold} action={action} />
      </ActionResultWrapper>
    </CompletedActionDiv>
  );
}
