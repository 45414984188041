import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { meetingSessionState, meetingRoomIdState } from 'states/meetingState';
import { ROOM } from './url';
import apiRequest from '.';
import { RoomStateResponse } from './types/response';
import { LanguageType, languageState } from 'states/localeState';

const fetcher = (session_id: string, room_id: number, lang: LanguageType) => {
  return apiRequest
    .get<RoomStateResponse>(ROOM.ROOM_INFO.replace(':roomId', String(room_id)), {
      params: {
        session_id,
        room_id,
        lang,
      },
    })
    .then(({ data }) => data);
};

/** 룸의 상태를 조회합니다 */
export default function useGetRoomInformation() {
  const sessionId = useRecoilValue(meetingSessionState);
  const roomId = useRecoilValue(meetingRoomIdState);
  const selectedLanguage = useRecoilValue<LanguageType>(languageState);
  return useQuery({
    queryKey: [ROOM.ROOM_INFO, sessionId, roomId],
    enabled: !!sessionId && !!roomId,
    queryFn: () => fetcher(sessionId, roomId, selectedLanguage),
  });
}
