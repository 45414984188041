import { GroupStageType } from 'apis/types/response';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const conversationDoneKey = 'conversation-done';
const persistKey = 'conversation-persist';

const { persistAtom } = recoilPersist({
  key: persistKey,
  storage: sessionStorage,
});

const clarification: CompleteStateValueType[] = [];

const preparation: CompleteStateValueType[] = [];

const discussion: CompleteStateValueType[] = [];

const vote: CompleteStateValueType[] = [];

/** 결론을 말하기 전에 하드코딩 된 내용이 끝났는지 판단여부 */
const conclusion: CompleteStateValueType[] = [];
const none: CompleteStateValueType[] = [];

export interface CompleteStateValueType {
  id: string;
  complete: boolean;
}

export type ConversationCompleteState = Record<GroupStageType, CompleteStateValueType[]>;

const initialState: ConversationCompleteState = {
  clarification,
  preparation,
  discussion,
  vote,
  conclusion,
  none,
};

export const conversationCompleteState = atom({
  key: conversationDoneKey,
  default: initialState,
  effects_UNSTABLE: [persistAtom],
});
