export const SESSION = {
  SESSION_LOGIN: `/api/session/login`,
};

export const MEETING = {
  START_MEETING: `/api/meeting/start`,
  GET_METTING_STATE: `/api/meeting/state`,
  GET_MINUTES: `/api/meeting/minutes`,
  GET_HISTORY: `/api/meeting/history`,
  POST_CLARIFICATION_MESSAGE: `/api/meeting/message`,
  STOP: '/api/meeting/stop',
  RESUME: `/api/meeting/resume`,
};

export const ROOM = {
  LIST: `/api/meeting/rooms`,
  ROOM_INFO: `/api/meeting/rooms/:roomId`,
};

// * 이하 old

export const CHAT = {
  LOGS: `/v1/chat/logs/`,
  CONTROL: `/v1/chat/control`,
  MESSAGE: `/v1/chat/messages/`,
  HISTORY: `/v1/history`,
  SUMMARY: `/v1/chat/results/summaries/`,
  TASKING: `/v1/chat/results/taskings/`,
  SKIP: `/v1/chats/skip`,
};

export const AGENT = {
  LOG: `/v1/agent-logs`,
};
