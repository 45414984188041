import { HighLightQuotesWrapper } from './HightLightQuotes.style';

const HighlightQuotes = ({ text, isCompleted }: { text: string; isCompleted?: boolean }) => {
  // 작은 따옴표가 있는 부분을 찾는 정규 표현식
  // const regex = /'\w+'/g;
  const example = `songhak's 'active billets' as a seokkyu's attempts`;
  const testRegex = /(?<!\w)'([^']+)'(?!\w)/g;
  // const regex = /'([^']*)'/g;

  // 텍스트를 정규 표현식을 사용하여 분할
  const parts = text.split(testRegex);

  return (
    <HighLightQuotesWrapper $completed={isCompleted}>
      {parts.map((part, index) =>
        // index가 홀수이면 작은 따옴표 안의 텍스트이므로 <b> 태그로 감싸기
        index % 2 === 1 ? <b key={index}>'{part}'</b> : part,
      )}
    </HighLightQuotesWrapper>
  );
};

export default HighlightQuotes;
