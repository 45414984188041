import { MeetingState } from 'apis/types/response';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const meetingSessionKey = 'meetingSessionKey';
const meetingRoomKey = 'meetingRoomKey';
const drawerKey = 'drawerKey';
const meetingStateKey = 'meetingStateKey';
const meetingRequestTimeStampKEy = 'requestTimeStampKey';

export const defaultRoomId = 0;
export const defaultMeetingSessionId = '';

const sessionStorage = typeof window !== 'undefined' ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: meetingSessionKey,
  storage: sessionStorage,
});

const { persistAtom: meetingRoomPersistAtom } = recoilPersist({
  key: meetingRoomKey,
  storage: sessionStorage,
});

/** 미팅의 세션 ID */
export const meetingSessionState = atom({
  key: meetingSessionKey,
  default: defaultMeetingSessionId,
  effects_UNSTABLE: [persistAtom], // * persist 삭제하려면 지우기
});

/** ROOM id 세팅 */
export const meetingRoomIdState = atom({
  key: meetingRoomKey,
  default: defaultRoomId, // TODO: 아이디 default 가 0이 괜찬한 ?
  effects_UNSTABLE: [meetingRoomPersistAtom], // * persist 삭제하려면 지우기
});

export const drawerState = atom({
  key: drawerKey,
  default: false,
});

/** meeting이 진행중인지, 에러인지, 종료인지 */
export const meetingStateAtom = atom({
  key: meetingStateKey,
  default: '' as MeetingState,
});

export const requestTimeStampAtom = atom({
  key: meetingRequestTimeStampKEy,
  default: '',
});
