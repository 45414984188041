import {
  Actions,
  GroupStageType,
  MeetingHistoryResponse,
  MeetingParticipants,
  MeetingStateResponse,
} from 'apis/types/response';
import useGetRoomInformation from 'apis/useGetRoomInformation';

import ArrowDown from 'assets/images/arrow-down-s-line.svg';

import {
  AgentConversationSide,
  AgentImg,
  AgentJob,
  AgentNameAndTimeDiv,
  AgentNameLineDiv,
  AgentOpinionDiv,
  AgentSide,
  AgnetName,
  AgnetTime,
  CenterDiv,
  ChatBox,
  ConversationFoldDiv,
  FoldBtnImg,
  FoldButton,
  GroupingChatConversationSection,
} from './GroupingChat.style';

import { ForwardedRef, RefObject, forwardRef, memo, useEffect, useRef, useState } from 'react';
import Locales from 'locale/*';
import {
  checkAllFieldDone,
  findPrevStepField,
  getHourMinTime,
  makeConversationCompleteData,
  makeFirstWordUppercase,
} from 'utils/utils';
import CompletedAction from './CompletedAction/CompletedAction';
import WorkingAction from './WorkingAction/WorkingAction';
import { useTypingEffect } from 'hooks/useTypingEffect';
import Slider from 'components/Slider/Slider';
import Participants from 'components/Participants/Participants';
import { useRecoilState } from 'recoil';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';

import useCompleteConversationInit from 'pages/MainPage/hooks/useCompleteConversationInit';
import HighlightQuotes from 'components/HighLightQuotes/HighLightQuotes';

interface GroupingChatProps {
  groupStageType: GroupStageType;
  data: MeetingHistoryResponse[];
  stepText: string;
  bodyRef: RefObject<HTMLDivElement>;
  /** 현재 스테이지를 분석 하고 있는지 */
  isCurrentStage: boolean;
  initMeetingState?: MeetingStateResponse;
}

interface GroupChatConversationProps {
  item: MeetingHistoryResponse;
  targetAgent: MeetingParticipants;
  agentOpinion?: Actions;
  ignoreMessageType?: Actions[];
  index: number;
  groupStageType: GroupStageType;
  groupId: string;
}

export const lottieStyle = { width: 24 };

export const AgentOpinion = memo(
  ({
    text,
    index,
    groupStageType,
    groupId,
  }: {
    text: string;
    index: number;
    groupId: string;
    groupStageType: GroupStageType;
  }) => {
    const { effectedText, isCompleted } = useTypingEffect({ text });
    const [conversationComplete, setConversationComplete] =
      useRecoilState<ConversationCompleteState>(conversationCompleteState);

    useEffect(() => {
      setConversationComplete({
        ...conversationComplete,
        [groupStageType]: makeConversationCompleteData(
          conversationComplete[groupStageType],
          groupId,
          'fail',
        ),
      });
    }, []);
    /** customHook 으로 뺄 경우, 의존성 배열에 이 함수를 집어넣어야 하는데, 넣게 되면 렌더가 너무 많이 일어나서 렉이 심해짐. 그래서 커스텀훅으로 빼지 않고 여기에 작성함. */
    const completeCallback = () => {
      // console.log('------------------------');
      // console.log(
      //   `clarification 단계: `,
      //   conversationComplete.clarification.map((item) => item.complete).join(', '),
      // );
      // console.log(
      //   `preparation 단계: `,
      //   conversationComplete.preparation.map((item) => item.complete).join(', '),
      // );
      // console.log(
      //   `discussion 단계: `,
      //   conversationComplete.discussion.map((item) => item.complete).join(', '),
      // );
      // console.log(`vote 단계: `, conversationComplete.vote.map((item) => item.complete).join(', '));
      // console.log(
      //   `conclusion 단계: `,
      //   conversationComplete.conclusion.map((item) => item.complete).join(', '),
      // );
      // console.log(groupStageType, groupId);
      // console.log('업데이트 data: ', {
      //   ...conversationComplete,
      //   [groupStageType]: makeConversationCompleteData(
      //     conversationComplete[groupStageType],
      //     groupId,
      //     'success',
      //   ),
      // });
      setConversationComplete({
        ...conversationComplete,
        [groupStageType]: makeConversationCompleteData(
          conversationComplete[groupStageType],
          groupId,
          'success',
        ),
      });
    };

    useEffect(() => {
      if (isCompleted) {
        const delayTime = Math.round(Math.random() * 300);
        setTimeout(() => {
          completeCallback();
        }, delayTime);
      }
    }, [isCompleted]);

    return (
      <AgentOpinionDiv>
        <HighlightQuotes isCompleted={isCompleted} text={effectedText} />
      </AgentOpinionDiv>
    );
  },
);

export const participantShowDelay = 1000;

export const groupShowDelay = participantShowDelay + 1500;

const delay = async (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const GroupChatConversation = (props: GroupChatConversationProps) => {
  const { item, targetAgent, agentOpinion, groupId, ignoreMessageType, index, groupStageType } =
    props;
  const [showWholeStep, setShowWholeStep] = useState(false);
  const [completedActions, setCompletedActions] = useState<Actions[]>([]);
  const setActive = () => {
    setShowWholeStep((prev) => !prev);
  };

  const isCompleted =
    (item &&
      item.completed_actions &&
      item.completed_actions?.filter((elem) => elem.action_type === 'message')?.length >= 1) ||
    false; // item.state === 'completed';

  return (
    <ChatBox key={item.group_id}>
      <AgentSide>
        <AgentImg src={targetAgent.icon_path} alt='icon' />
        {/* <AgentJob>{targetAgent.persona.job}</AgentJob> */}
      </AgentSide>
      <AgentConversationSide>
        <AgentNameLineDiv>
          <AgentNameAndTimeDiv>
            <AgnetName>
              {makeFirstWordUppercase(targetAgent.name)} (
              {makeFirstWordUppercase(targetAgent.persona.job)})
            </AgnetName>
            <AgnetTime>{getHourMinTime(item.request_timestamp)}</AgnetTime>
          </AgentNameAndTimeDiv>
          <ConversationFoldDiv>
            <Slider label={Locales.SEE_FULL} active={showWholeStep} setActive={setActive} />
          </ConversationFoldDiv>
        </AgentNameLineDiv>
        {agentOpinion && (
          <AgentOpinion
            groupStageType={groupStageType}
            text={String(agentOpinion.result)}
            index={index}
            groupId={groupId}
          />
        )}
        {!showWholeStep && <WorkingAction isCompleted={isCompleted} action={item.working_action} />}

        {showWholeStep &&
          ignoreMessageType?.map((completeAction, idx) => (
            <CompletedAction action={completeAction} key={idx} />
          ))}
        {showWholeStep && <WorkingAction isCompleted={isCompleted} action={item.working_action} />}
      </AgentConversationSide>
    </ChatBox>
  );
};
export interface CompletesType {
  completed: boolean;
  id: string;
}
const GroupingChat = forwardRef((props: GroupingChatProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { initMeetingState, groupStageType, stepText, data, bodyRef, isCurrentStage } = props;
  const { data: roomInfoData } = useGetRoomInformation();
  const [groupShow, setGroupShow] = useState(false);
  const [fold, setFold] = useState(false);
  const [conversationComplete, setConversationComplete] =
    useRecoilState<ConversationCompleteState>(conversationCompleteState);
  // console.log(conversationComplete);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const prevFieldKey = findPrevStepField(groupStageType);
  const prevStageDone = checkAllFieldDone(conversationComplete[prevFieldKey]);
  if (groupStageType === 'preparation') {
    // console.log(conversationComplete.clarification);
    // console.log('이전 단계가 끝났는지 여부: ', prevStageDone);
  }
  useCompleteConversationInit(data, groupStageType, initMeetingState);

  const onClickFoldButton = () => {
    setFold((prev) => !prev);
  };

  useEffect(() => {
    if (isCurrentStage && prevStageDone) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [wrapperRef.current, isCurrentStage, prevStageDone, bodyRef.current]);

  const hasStage = data.filter((elem) => elem.stage === groupStageType).length >= 1;

  /** 등장 애니메이션 제어 */
  useEffect(() => {
    if (prevStageDone) {
      if (roomInfoData && hasStage) {
        setTimeout(() => {
          setGroupShow(true);
        }, groupShowDelay);
      } else {
        setGroupShow(false);
      }
    }
  }, [prevStageDone, roomInfoData, hasStage]);

  if (prevStageDone && roomInfoData && hasStage) {
    // console.log(data);
    return (
      <div ref={wrapperRef}>
        <Participants
          roomInfoData={roomInfoData}
          hasStage={hasStage}
          groupStageType={groupStageType}
          prevStageDone={prevStageDone}
        />

        <CenterDiv $hideBtn={!fold}>
          <FoldButton onClick={onClickFoldButton}>
            {stepText}
            <FoldBtnImg src={ArrowDown} />
          </FoldButton>
        </CenterDiv>

        <GroupingChatConversationSection $fold={fold} ref={ref} $show={groupShow}>
          <CenterDiv style={{ marginBottom: 24 }}>
            <FoldButton onClick={onClickFoldButton}>
              {stepText}
              <FoldBtnImg src={ArrowDown} $reverse />
            </FoldButton>
          </CenterDiv>
          {groupShow &&
            data.map((item, index) => {
              const targetAgent = roomInfoData.participants.filter(
                (elem) => elem.name === item.agent_name,
              )[0];

              const completedActions = item.completed_actions?.filter(
                (elem) => elem.action_state === 'completed',
              );
              const agentOpinion = completedActions?.filter(
                (elem) => elem.action_type === 'message',
              )?.[0];

              const ignoreMessageType = item.completed_actions?.filter(
                (elem) => elem.action_type !== 'message',
              );

              return (
                <GroupChatConversation
                  key={item.group_id}
                  groupId={item.group_id}
                  item={item}
                  index={index}
                  agentOpinion={agentOpinion}
                  targetAgent={targetAgent}
                  ignoreMessageType={ignoreMessageType}
                  groupStageType={groupStageType}
                />
              );
            })}
        </GroupingChatConversationSection>
      </div>
    );
  }
  return null;
});
export default GroupingChat;
