import { GroupStageType } from 'apis/types/response';
import {
  Circle,
  SideWindoeTextWrap,
  SideWindowStep,
  SideWindowWrapper,
  StepText,
  Title,
} from './SideWindow.style';
import Locales from 'locale/*';
import { RefObject, useState } from 'react';
import { HEADER_HEIGHT } from 'pages/MainPage/MainPage.styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { conversationCompleteState } from 'states/conversationCompleteState';
import { checkAllFieldDone, findPrevStepField } from 'utils/utils';
import { ToastType, toastState } from 'states/toastState';

interface SideWindowProps {
  clarificationRef: RefObject<HTMLDivElement>;
  preparationRef: RefObject<HTMLDivElement>;
  disccusionRef: RefObject<HTMLDivElement>;
  voteRef: RefObject<HTMLDivElement>;
  conclusionRef: RefObject<HTMLDivElement>;
  currentStage: GroupStageType;
}

const ArrowRight = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.1714 11.9999L8.22168 7.05006L9.63589 5.63586L15.9999 11.9999L9.63589 18.3638L8.22168 16.9496L13.1714 11.9999Z'
        fill='#94A3B8'
      />
    </svg>
  );
};

const calcListLevel = (stage: GroupStageType) => {
  const listLevel: GroupStageType[] = [
    'clarification',
    'preparation',
    'discussion',
    'vote',
    'conclusion',
  ];
  return listLevel.findIndex((elem) => elem === stage);
};

const calcIsCurrent = (
  idx: number,
  currentStageLevel: number,
  checkPrevConversationComplete: boolean,
) => {
  if (idx === currentStageLevel && idx === 0) return true;
  else if (idx === currentStageLevel && checkPrevConversationComplete) return true;
  else if (!checkPrevConversationComplete && idx === currentStageLevel - 1) {
    /** 이전 스테이지의 텍스트 입력이 끝나지 않았을 때 */
    return true;
  }

  return false;
};

export default function SideWindow(props: SideWindowProps) {
  const {
    currentStage,
    conclusionRef,
    clarificationRef,
    disccusionRef,
    preparationRef,
    voteRef,
    // observerResult,
  } = props;
  const list = [
    { name: Locales.STEP_0, ref: clarificationRef },
    { name: Locales.STEP_1, ref: preparationRef },
    { name: Locales.STEP_2, ref: disccusionRef },
    { name: Locales.STEP_3, ref: voteRef },
    { name: Locales.STEP_4, ref: conclusionRef },
  ];

  const conversationState = useRecoilValue(conversationCompleteState);
  const prevStage = findPrevStepField(currentStage);
  const prevConversationState = conversationState[prevStage];
  const checkPrevConversationAllComplete = checkAllFieldDone(prevConversationState);
  const [toast, setToast] = useRecoilState<ToastType>(toastState);

  const currentStageLevel = calcListLevel(currentStage);
  const onClick = (ref: RefObject<HTMLDivElement>, level: number) => {
    if (currentStageLevel === -1) {
      alert(Locales.PLEASE_TYPING_PROMPT);
    } else {
      if (currentStageLevel >= level) {
        const top = Number(ref.current?.offsetTop) - (HEADER_HEIGHT + 10);
        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      } else {
        if (!toast.show) {
          setToast({
            show: true,
            status: 'normal',
            message: Locales.ANALYZING,
          });
        }
      }
    }
  };

  return (
    <SideWindowWrapper>
      <Title>{Locales.PROCESS}</Title>
      {list.map((step, idx) => {
        const isCurrent = calcIsCurrent(idx, currentStageLevel, checkPrevConversationAllComplete);

        const isPrev = idx < currentStageLevel;
        const isPrevWithCurrent = idx <= currentStageLevel;
        return (
          <SideWindowStep
            $isCurrent={isCurrent}
            $isPrev={isPrev}
            onClick={() => onClick(step.ref, idx)}
            key={idx}
          >
            <SideWindoeTextWrap>
              <Circle $selected={isCurrent} />
              <StepText $isCurrent={isCurrent}>{step.name}</StepText>
            </SideWindoeTextWrap>
            {isPrevWithCurrent && <ArrowRight />}
          </SideWindowStep>
        );
      })}
    </SideWindowWrapper>
  );
}
