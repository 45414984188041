import { useMutation } from '@tanstack/react-query';
import { SESSION } from './url';
import apiRequest from '.';
import { CreateSessionResponse } from './types/response';
import { defaultMeetingSessionId, meetingSessionState } from 'states/meetingState';
import { useSetRecoilState } from 'recoil';
import Locales from 'locale/*';

const fetcher = (username: string, password: string) => {
  return apiRequest
    .post<CreateSessionResponse>(SESSION.SESSION_LOGIN, null, {
      params: {
        username,
        password,
      },
    })
    .then(({ data }) => data);
};

export default function usePostLogin() {
  const setSessionState = useSetRecoilState(meetingSessionState);
  return useMutation({
    mutationKey: [SESSION.SESSION_LOGIN],
    mutationFn: ({ username, password }: { username: string; password: string }) =>
      fetcher(username, password),
    onError(error: any) {
      setSessionState(defaultMeetingSessionId);
      if (error?.response?.status === 401) {
        alert(Locales.PASSWORD);
      } else {
        alert(Locales.LOGIN_ERROR);
      }
    },
    onSuccess(data) {
      setSessionState(data.session_id);
    },
    // throwOnError: true,
  });
}
