import { QueryClientProvider } from '@tanstack/react-query';
import { myQueryClient } from 'utils/react-query-utils';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import ReactDOM from 'react-dom/client';
import App from './App';
import { RecoilRoot } from 'recoil';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage });

persistQueryClient({
  queryClient: myQueryClient,
  persister: sessionStoragePersister,
});

// if (process.env.REACT_APP_MODE === 'mock') {
//   worker.start();
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={myQueryClient}>
    <RecoilRoot>
      <App />
      <ReactQueryDevtools />
    </RecoilRoot>
  </QueryClientProvider>,
);
