/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionResultType, Actions } from 'apis/types/response';
import { ActionResultBody } from './CompletedAction.style';
import UnderstandIntentionContent from './components/UnderstandIntention/UndersatndIntention';
import CreateStatementOfWork from './components/CreateStatementOfWork/CreateStatementOfWork';
import SelectCaseAndExecute from './components/SelectCaseAndExecute/SelectCaseAndExecute';
import Message from './components/Message/Message';
import OntologyLanguage from './components/OntologyLanguage/OntologyLanauge';
import OntologyKnowledgeForClarify from './components/OntologyKnowledgeForClarify/OntologyKnowledgeForClarify';
import OntologyKnowledge from './components/OntologyKnowledge/OntologyKnowledge';
import Analysis from './components/Analysis/Analysis';
import Speaking from './components/Speaking/Speaking';
import Vote from './components/Vote/Vote';
// import 'highlight.js/styles/github-dark.css';
interface ResultBodyProps extends ResultContent {
  fold: boolean;
}

export interface ResultContent {
  action: Actions;
}

/** CompletedAction, WorgkingAction 표시 */
export default function ResultBody(props: ResultBodyProps) {
  const { action, fold } = props;
  // console.log(action);

  const Component: Record<ActionResultType, React.ReactElement> = {
    ['intention']: <UnderstandIntentionContent action={action} />,
    ['sow']: <CreateStatementOfWork action={action} />,
    ['ontology_language']: <OntologyLanguage action={action} />,
    ['ontology_knowledge_for_clarify']: <OntologyKnowledgeForClarify action={action} />,
    ['ontology_knowledge']: <OntologyKnowledge action={action} />,
    ['data']: <SelectCaseAndExecute action={action} />,
    ['analysis']: <Analysis action={action} />,
    ['speak']: <Speaking action={action} />,
    ['message']: <Message action={action} />,
    ['vote']: <Vote action={action} />,
    ['summary']: <div>summary</div>, // * ConclusionChat 에서 구현.
  };
  return <ActionResultBody $fold={fold}>{Component[action.result_type]}</ActionResultBody>;
}
