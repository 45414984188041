import { useQuery } from '@tanstack/react-query';
import apiRequest from 'apis/index';
import { MeetingStateResponse } from 'apis/types/response';
import { MEETING } from 'apis/url';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { meetingRoomIdState, meetingSessionState } from 'states/meetingState';

// export default function useInitialMeetingState() {
//   const session_id = useRecoilValue(meetingSessionState);
//   const room_id = useRecoilValue(meetingRoomIdState);
//   const [apiReesult, setApiReesult] = useState<MeetingStateResponse>();

//   useEffect(() => {
//     console.log({ session_id, room_id });
//   }, [session_id, room_id]);

//   useEffect(() => {
//     // console.log({ session_id, room_id });
//     apiRequest
//       .get<MeetingStateResponse>(MEETING.GET_METTING_STATE, {
//         params: {
//           session_id,
//           room_id,
//         },
//       })
//       .then(({ data }) => {
//         return setApiReesult(data);
//       });
//     return () => {
//       setApiReesult(undefined);
//     };
//   }, [session_id, room_id]);
//   return apiReesult;
// }

const fetcher = (session_id: string, room_id: number) => {
  return apiRequest
    .get<MeetingStateResponse>(MEETING.GET_METTING_STATE, {
      params: {
        session_id,
        room_id,
      },
    })
    .then(({ data }) => data);
};

export default function useInitialGetMeetingState() {
  const session_id = useRecoilValue(meetingSessionState);
  const room_id = useRecoilValue(meetingRoomIdState);
  return useQuery({
    queryFn: () => fetcher(session_id, room_id),
    queryKey: [MEETING.GET_METTING_STATE, 'init', session_id, room_id],
    enabled: !!room_id && !!session_id,
  });
}
