import { ConclusionSummaryType } from 'apis/types/response';
import hostImg from 'assets/images/host.png';
import {
  AgentChatTextDiv,
  AgentChatTextWrapper,
  AgentChatTitleDiv,
  AgentChatWrapper,
  ConClusionWrapper,
} from 'pages/MainPage/components/Chat/components/AgentChat/AgentChat.style';
import {
  InfoDiv,
  TitleName,
  TitleTime,
  UserChatDiv,
  UserIcon,
} from 'pages/MainPage/components/Chat/components/UserChat/UserChat.style';
import { getHourMinTime } from 'utils/utils';
import {
  ConclusionIconDiv,
  Reason,
  ReasonWrapper,
  SummaryReasonText,
} from './ConclusionSummary.style';
import FeelIcon from 'assets/images/feel-icon.svg';
import Locales from 'locale/*';
import HighlightQuotes from 'components/HighLightQuotes/HighLightQuotes';
import { useTypingEffect } from 'hooks/useTypingEffect';
interface ConclusionSummaryProps {
  name: string;
  time: string;
  text: ConclusionSummaryType;
}
export default function ConclusionSummary(props: ConclusionSummaryProps) {
  const { name, text, time } = props;
  const { reason, summary } = text;
  const { effectedText: reasonEffectedText, isCompleted: reasonCompleted } = useTypingEffect({
    text: reason,
  });
  const { effectedText: summaryEffectedText, isCompleted: summaryCompleted } = useTypingEffect({
    text: summary,
    // enabled: reasonCompleted,
  });
  return (
    <AgentChatWrapper>
      <UserChatDiv>
        <UserIcon src={hostImg} />
        <InfoDiv>
          <AgentChatTitleDiv>
            <TitleName>{name}</TitleName>
            <TitleTime>{getHourMinTime(time)}</TitleTime>
          </AgentChatTitleDiv>
          <AgentChatTextWrapper>
            <AgentChatTextDiv $isFinal>
              <ConClusionWrapper $paddingBottom={20} $marginBottom={20} $column>
                <ConclusionIconDiv>
                  {/* <img src={FeelIcon} /> */}
                  <div>{Locales.CONCLUSION_SUMMARY}</div>
                </ConclusionIconDiv>
                <SummaryReasonText>
                  <HighlightQuotes isCompleted={reasonCompleted} text={reasonEffectedText} />
                </SummaryReasonText>
              </ConClusionWrapper>
              <ReasonWrapper>
                <SummaryReasonText>
                  <Reason>{Locales.REASON}</Reason>
                  <HighlightQuotes isCompleted={summaryCompleted} text={summaryEffectedText} />
                </SummaryReasonText>
              </ReasonWrapper>
            </AgentChatTextDiv>
          </AgentChatTextWrapper>
        </InfoDiv>
      </UserChatDiv>
    </AgentChatWrapper>
  );
}
