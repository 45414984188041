import { MeetingState, StartMeetingResponse } from 'apis/types/response';

export interface RoomListData {
  agenda: string;
  status: MeetingState;
  roomId: number;
  participants: {
    name: string;
    url: string;
  }[];
}

export default function useRoomListTranser(data?: StartMeetingResponse[]): RoomListData[] | null {
  if (data) {
    const result: RoomListData[] = data.map((item) => {
      return {
        agenda: item.agenda,
        status: item.state,
        roomId: item.room_id,
        participants: item.participants.map((person) => ({
          name: person.name,
          url: person.icon_path,
        })),
      };
    });
    return result;
  }
  return null;
}
