import { useState } from 'react';
import { Circle, HorizonBar, IconWrapper, SliderLabel, SliderWrapper } from './Slider.style';

interface SliderProps {
  label: string;
  active: boolean;
  setActive: () => void;
  /** 비활성화 됐을 때 색 */
  inActiveColor?: string;
}

export default function Slider(props: SliderProps) {
  const { label, active, setActive, inActiveColor } = props;

  return (
    <SliderWrapper>
      <SliderLabel>{label}</SliderLabel>
      <IconWrapper onClick={setActive}>
        <Circle $isActive={active} />
        <HorizonBar $isActive={active} $inActiveColor={inActiveColor} />
      </IconWrapper>
    </SliderWrapper>
  );
}
