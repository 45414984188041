import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const ParticipantsDiv = styled.div<{ $show?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  transition: opacity 0.2s ease-out;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
`;

export const ParticipantsWrapper = styled.div<{ $width: number }>`
  position: relative;
  height: 44px;
  width: ${({ $width }) => $width}px;
`;

export const AgendaTitleWrapper = styled.div`
  ${typography.body(700)}
  color:${colors.gray400};
`;

export const ParticipantsImg = styled.img<{ $left: number }>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-size: cover;
  position: absolute;
  left: ${({ $left }) => $left}px;
`;
