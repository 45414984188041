/* eslint-disable react-hooks/exhaustive-deps */
import { GroupStageType, MeetingHistoryResponse, MeetingStateResponse } from 'apis/types/response';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  CompleteStateValueType,
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';
import { checkAllFieldDone, conversationDataMerge, makeConversationDefaultData } from 'utils/utils';

const completeValue: CompleteStateValueType[] = [
  {
    complete: true,
    id: '',
  },
];

/** 대화가 시작되면 기본 데이터를 세팅한다(groupId와 completed를 false로) */
export default function useCompleteConversationInit(
  data: MeetingHistoryResponse[],
  groupStageType: GroupStageType,
  meetingState?: MeetingStateResponse,
) {
  const [conversationComplete, setConversationComplete] =
    useRecoilState<ConversationCompleteState>(conversationCompleteState);

  useEffect(() => {
    if (data.length >= 1) {
      // const voteDone = checkAllFieldDone(conversationComplete.vote);
      if (meetingState?.state === 'end' || meetingState?.state === 'error') {
        setConversationComplete({
          ...conversationComplete,
          clarification: completeValue,
          preparation: completeValue,
          vote: completeValue,
          discussion: completeValue,
          conclusion: completeValue,
        });
      } else {
        const initialData = makeConversationDefaultData(data, false);
        const mergeData = conversationDataMerge(conversationComplete[groupStageType], initialData);
        // console.log('값 변함');
        if (groupStageType === 'clarification') {
          // console.log('merge data: ', mergeData);
        }
        setConversationComplete({
          ...conversationComplete,
          [groupStageType]: mergeData,
        });
      }
    }
  }, [data]);
}
