/* eslint-disable prefer-const */
import { Actions, MeetingHistoryResponse } from 'apis/types/response';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { meetingHistoryMessageState } from 'states/myMessageState';

/**
 *
 * @param prevData 이전 데이터
 * @param newData 새로 받은 데이터
 * @description 이전 응답과 새로온 응답의 completed_actions 와 working_action 을 갱신함.
 */

export function mergeGroup(prevData: MeetingHistoryResponse[], newData: MeetingHistoryResponse[]) {
  let result = structuredClone(prevData); // JSON.parse(JSON.stringify([...prevData]));
  // let result = new Array(...prevData) as MeetingHistoryResponse[]; // JSON.parse(JSON.stringify([...prevData]));
  /**
   * 새로운 응답값과 일치하는 group_id 를 찾는다.
   * 이전 데이터에 있는 completed_actions 에 새로운 데이터의 completed_actions 를 추가한다.
   * 새로운 데이터에 working_actions가 있다면 대체한다.
   *
   * 이전 데이터와 일치하는 group_id 가 없다면 새로운 응답을 추가한다.
   */

  for (let i = 0; i < newData.length; i++) {
    const isExistInPrevData =
      prevData.filter((prev) => prev.group_id === newData[i].group_id).length >= 1;
    const prevDataIdx = prevData.findIndex((item) => item.group_id === newData[i].group_id);
    /** 이전 응답이 존재할 때 */
    if (isExistInPrevData) {
      if (newData[i]?.completed_actions) {
        /** 이전 그룹에 completed_actions가 있으면 */
        if (result[prevDataIdx].completed_actions) {
          /** 이전 그룹의 completed_actions 에 새로받은 데이터의 action_id가 겹치지 않는것만 뽑아낸다. */
          const filteredCompletedAction = newData[i].completed_actions?.filter((action) => {
            const hasSameActionId = (result[prevDataIdx].completed_actions as Actions[])
              .map((item) => item.action_id)
              .includes(action.action_id);

            if (!hasSameActionId) {
              return action;
            }
          });

          result[prevDataIdx].completed_actions = result[prevDataIdx]?.completed_actions?.concat(
            ...(filteredCompletedAction as Actions[]),
          );
        } else {
          /** 이전 데이터에 completed_actions가 있으면 */
          result[prevDataIdx].completed_actions = newData[i].completed_actions;
          result[prevDataIdx].request_timestamp = newData[i].request_timestamp;
        }
      }
      if (newData[i]?.working_action) {
        result[prevDataIdx].working_action = newData[i].working_action;
        result[prevDataIdx].request_timestamp = newData[i].request_timestamp;
      } else {
        delete result[prevDataIdx].working_action;
      }
    } else {
      /** 이전 데이터 배열 뒤에 추가한다 */
      result.push(newData[i]);
    }
  }

  return result;
}

export default function useRecomposeHistoryData(data?: MeetingHistoryResponse[]) {
  const [meetingHistoryMessage, setMeetingHistoryMessage] = useRecoilState(
    meetingHistoryMessageState,
  );

  useEffect(() => {
    if (data && data?.length >= 1) {
      setMeetingHistoryMessage((prev: MeetingHistoryResponse[]) => {
        // console.log('API에서 가져온 데이터: ', data);
        const result = mergeGroup(prev, data);
        // console.log('머지되는 데이터: ', result);
        return [...result];
      });
    }
  }, [data]);
}
