import Locales from 'locale/*';
import { PromptTextArea, SendBtnSvg, SvgLabel, Wrapper } from './Footer.style';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { colors } from 'styles/colors';
import useStartMeeting from 'apis/useStartMeeting';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { meetingStateAtom } from 'states/meetingState';
import useGetMeetingState from 'apis/useGetMeetingState';
import useMeetingStateFollower from './hooks/useMeeingStateFollower';
import { meetingHistoryMessageState, myMessageState } from 'states/myMessageState';
import Stop from './components/Stop/Stop';
import { loggedInUserState } from 'states/loggedInUserState';
import { calcMeetingStateEnabled } from 'utils/utils';
import useClarificationMessage from 'apis/usePostClarificationMessage';
import { questionState } from 'states/questinoState';
import useDefaultPrompt from './hooks/useDefaultPrompt';
import useSvgColor from './hooks/useSvgColor';

const lineHeight = 24;
const minHeight = 56;
const maxHeight = 256;

function heightCalc(enterCount: number) {
  const calcHeight = enterCount * lineHeight;
  if (calcHeight >= maxHeight) return maxHeight;
  if (enterCount >= 1) return minHeight + calcHeight;
  return minHeight;
}

const SendBtn = ({ svgColor }: { svgColor: SvgColor }) => {
  return (
    <SendBtnSvg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='8' fill={svgColor.bg} />
      <path
        d='M12.5 20.8332H17.5V19.1666H12.5V11.538C12.5 11.3079 12.6866 11.1213 12.9167 11.1213C12.9869 11.1213 13.0559 11.139 13.1175 11.1729L28.5028 19.6348C28.7045 19.7457 28.7781 19.9991 28.6672 20.2007C28.6291 20.27 28.5721 20.327 28.5028 20.3651L13.1175 28.827C12.9158 28.9379 12.6625 28.8643 12.5516 28.6627C12.5177 28.6012 12.5 28.5322 12.5 28.4619V20.8332Z'
        fill={svgColor.path}
      />
    </SendBtnSvg>
  );
};

const defaultColor = {
  bg: colors.disabledBtn,
  path: colors.gray400,
};
export const activeColor = {
  bg: colors.primary,
  path: colors.white,
};
export interface SvgColor {
  bg: string;
  path: string;
}

export default function Footer() {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState(minHeight);
  const [selectQuestion, setSelectQuestion] = useRecoilState(questionState);
  const [textValue, setTextValue] = useState('');
  useDefaultPrompt(setTextValue);
  const [svgColor, setSvgColor] = useState<SvgColor>(defaultColor);
  const [meetingHistoryMessage] = useRecoilState(meetingHistoryMessageState);
  // console.log('대화기록: ', meetingHistoryMessage);
  const { mutateAsync: startMeetingMutateAsync, status: startMeetingStatus } = useStartMeeting();
  const { mutateAsync: clarificationMessageMutateAsync, status: clarificationStatus } =
    useClarificationMessage();
  const myMessages = useRecoilValue(myMessageState);
  const setMyMessages = useSetRecoilState(myMessageState);
  const loggedInUserInfo = useRecoilValue(loggedInUserState);
  const [_, setCount] = useState(0);
  const meetingState = useRecoilValue(meetingStateAtom);
  /** 종료되거나, 에러면 멈춘다. */
  const enabled = calcMeetingStateEnabled(meetingState);
  const { data } = useGetMeetingState(enabled);
  useMeetingStateFollower(data?.state);
  useSvgColor(textValue, setSvgColor);
  const lastClickTimeStamp = useRef(0);

  /** 마지막 응답이 clarification 인지 확인한다. */
  const isClarificationStatus =
    meetingHistoryMessage[meetingHistoryMessage.length - 1]?.stage === 'clarification' ||
    meetingHistoryMessage[meetingHistoryMessage.length - 1]?.stage === 'none';

  /** Locale 변경을 위해 임시 렌더 추가 */
  useEffect(() => setCount((prev) => prev + 1), []);
  useEffect(() => {
    if (textValue) {
      const enterCount = textValue.split('\n').length - 1;
      const height = heightCalc(enterCount);
      setTextAreaHeight(height);
    } else setTextAreaHeight(minHeight);
  }, [textValue]);
  const btnDisabled = startMeetingStatus === 'pending' || clarificationStatus === 'pending';

  const onClickSend = async () => {
    const now = Date.now();
    if (!!textValue) {
      if (!btnDisabled) {
        // console.log(now);
        // console.log(lastClickTimeStamp.current);
        if (now - lastClickTimeStamp.current < 10) {
          return;
        }
        lastClickTimeStamp.current = now;

        if (isClarificationStatus) {
          await clarificationMessageMutateAsync(textValue);
        } else {
          await startMeetingMutateAsync(textValue);
        }
        setMyMessages([
          ...myMessages,
          {
            name: String(loggedInUserInfo?.username),
            message: textValue,
            time: new Date().toISOString(),
          },
        ]);

        setTextValue('');
        setSelectQuestion('');
      } else {
        alert(Locales.PROCESSING);
      }
    } else {
      alert(Locales.TYPING_MESSAGE);
    }
  };

  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const splited = e.target.value.split('');
    const isLastCharacterLineChange = splited[splited.length - 1] === '\n';
    if (isLastCharacterLineChange) {
      setTextValue(e.target.value.slice(0, -1));
    } else {
      setTextValue(e.target.value);
    }
  };

  const onKeyDownTextArea = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!e.shiftKey && e.code === 'Enter') {
      await onClickSend();
    }
  };

  const disapper = data?.state === 'end' || data?.state === 'error' || data?.state === 'stop';

  return (
    <Wrapper>
      {data?.state === 'working' && !isClarificationStatus ? (
        <>
          <Stop />
        </>
      ) : (
        <>
          {disapper ? (
            <></>
          ) : (
            <>
              <PromptTextArea
                onChange={onChangeTextArea}
                ref={textAreaRef}
                onKeyDown={onKeyDownTextArea}
                placeholder={Locales.TEXT_AREA_PLACEHOLDER}
                style={{
                  height: textAreaHeight,
                }}
                value={textValue}
                onFocus={() => setSvgColor(activeColor)}
                onBlur={() => setSvgColor(defaultColor)}
              />
              <SvgLabel htmlFor='send-btn'>
                <SendBtn svgColor={svgColor} />
              </SvgLabel>

              <button
                disabled={btnDisabled}
                onClick={onClickSend}
                style={{ opacity: 0 }}
                id='send-btn'
              ></button>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}
