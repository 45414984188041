import { Dispatch, SetStateAction, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { questionState } from 'states/questinoState';

/** Welcome 에서 선택한 텍스트가 프롬프트 입력창에 입력되게 한다. */
export default function useDefaultPrompt(setTextValue: Dispatch<SetStateAction<string>>) {
  const [selectQuestion, _] = useRecoilState(questionState);
  useEffect(() => {
    setTextValue(selectQuestion);
  }, [selectQuestion, setTextValue]);
}
