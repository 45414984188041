import { css } from 'styled-components';

type TypographyFontWeight = 100 | 300 | 400 | 500 | 700 | 900;

export const typography = {
  body: (fontWeight?: TypographyFontWeight) => css`
    font-size: 15px;
    font-weight: ${fontWeight || 400};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
  caption: (fontWeight?: TypographyFontWeight) => css`
    font-size: 13px;
    font-weight: ${fontWeight || 400};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
  h4: (fontWeight?: TypographyFontWeight) => css`
    font-size: 17px;
    font-weight: ${fontWeight || 400};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
  h3: (fontWeight?: TypographyFontWeight) => css`
    font-size: 22px;
    font-weight: ${fontWeight || 700};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
  h2: (fontWeight?: TypographyFontWeight) => css`
    font-size: 32px;
    font-weight: ${fontWeight || 700};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
  h1: (fontWeight?: TypographyFontWeight) => css`
    font-size: 36px;
    font-weight: ${fontWeight || 700};
    line-height: 1.5;
    letter-spacing: 0em;
  `,
};
