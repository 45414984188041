import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const StateOfWorkItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SowTableDeliverablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SowDeliverablesItem = styled.div`
  padding-left: 24px;
  color: ${colors.white};
  ${typography.body(400)}
  position: relative;
  &::before {
    content: '';
    background-color: ${colors.white};
    width: 3px;
    height: 3px;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50%;
  }
`;
