/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getHourMinTime, makeConversationCompleteData } from 'utils/utils';
import Lottie from 'lottie-react';
import thinkLottie from 'assets/lottie/ing.json';
import RestartLine from 'assets/images/restart-line.svg';
import { UserChatProps } from '../UserChat/UserChat';
import hostImg from 'assets/images/host.png';
import { InfoDiv, TitleName, TitleTime, UserChatDiv, UserIcon } from '../UserChat/UserChat.style';
import ConclusionMessage from 'assets/images/conclusion_message.svg';
import {
  AgentChatTextDiv,
  AgentChatTextWrapper,
  AgentChatTitleDiv,
  AgentChatWrapper,
  ConClusionWrapper,
  ErrorIcon,
  ErrorResumeButton,
} from './AgentChat.style';
import { useTypingEffect } from 'hooks/useTypingEffect';
import { Dispatch, SetStateAction, memo, useEffect, useRef } from 'react';
import Locales from 'locale/*';
import { LottieWrapper } from 'components/RoomDrawer/RoomDrawer.style';

import { useRecoilState } from 'recoil';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';
import { GroupStageType } from 'apis/types/response';
import useResumeMetting from 'apis/useResumeMeeting';
import HighlightQuotes from 'components/HighLightQuotes/HighLightQuotes';

interface AgentChatProps extends UserChatProps {
  isError?: boolean;
  isFinal?: boolean;
  index?: number;
  groupId?: string;
  groupStateType?: GroupStageType;
  widthLimit?: boolean;
}

const AgentChat = (props: AgentChatProps) => {
  const { widthLimit, name, isError, groupStateType, text, time, isFinal, groupId } = props;
  const [conversationComplete, setConversationComplete] =
    useRecoilState<ConversationCompleteState>(conversationCompleteState);

  const { effectedText, isCompleted } = useTypingEffect({ text });
  const { mutate: resumeMutate } = useResumeMetting();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && isError) {
      wrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isError, wrapperRef.current]);

  useEffect(() => {
    if (groupId && groupStateType) {
      setConversationComplete({
        ...conversationComplete,
        [groupStateType]: makeConversationCompleteData(
          conversationComplete[groupStateType],
          groupId,
          'fail',
        ),
      });
    }
  }, []);

  const animationDoneCallback = () => {
    // console.log('현재 기본 상태:', conversationComplete);
    if (groupId && groupStateType) {
      // console.log(groupStateType, groupId);
      // console.log('이렇게 변경 할거야!', {
      //   ...conversationComplete,
      //   [groupStateType]: makeConversationCompleteData(
      //     conversationComplete[groupStateType],
      //     groupId,
      //     'success',
      //   ),
      // });

      setConversationComplete({
        ...conversationComplete,
        [groupStateType]: makeConversationCompleteData(
          conversationComplete[groupStateType],
          groupId,
          'success',
        ),
      });
      // console.log('set 지나옴!!');
    }
  };

  useEffect(() => {
    if (isCompleted) {
      const delayTime = Math.round(Math.random() * 300);
      setTimeout(() => {
        animationDoneCallback();
      }, delayTime);
    }
  }, [isCompleted]);

  // useTypingAnimationDone(isCompleted, conversationComplete, animationDoneCallback);

  const onClickErrorResume = () => {
    resumeMutate();
  };

  return (
    <AgentChatWrapper ref={wrapperRef}>
      {isError && <ErrorIcon>!</ErrorIcon>}
      <UserChatDiv>
        <UserIcon src={hostImg} />
        <InfoDiv>
          <AgentChatTitleDiv>
            <TitleName>{name}</TitleName>
            <TitleTime>{getHourMinTime(time)}</TitleTime>
          </AgentChatTitleDiv>
          <AgentChatTextWrapper>
            <AgentChatTextDiv $isError={isError} $isFinal={isFinal} $widthLimit={widthLimit}>
              {isFinal && (
                <ConClusionWrapper>
                  <img src={ConclusionMessage} />
                  <span>{Locales.STEP_4}</span>
                </ConClusionWrapper>
              )}
              {text === Locales.THINKING && (
                <LottieWrapper style={{ marginRight: 5 }}>
                  <Lottie animationData={thinkLottie} loop={true} />
                </LottieWrapper>
              )}
              <HighlightQuotes
                isCompleted={effectedText === Locales.THINKING || isCompleted}
                text={effectedText}
              />
            </AgentChatTextDiv>
            {isError && (
              <ErrorResumeButton onClick={onClickErrorResume}>
                <img src={RestartLine} />
                <span>{Locales.RETRY}</span>
              </ErrorResumeButton>
            )}
          </AgentChatTextWrapper>
        </InfoDiv>
      </UserChatDiv>
    </AgentChatWrapper>
  );
};

export default memo(AgentChat);
