import styled, { keyframes } from 'styled-components';
import { PAGE_WIDTH } from 'pages/MainPage/MainPage.styles';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

const width = 160;
const gap = 60;
const height = 266;

// const pulse = keyframes`
// 0% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(50, 93, 166, 0.7);
// 	}

// 	70% {
// 		transform: scale(1);
// 		box-shadow: 0 0 0 10px rgba(50, 93, 166, 0);
// 	}

// 	100% {
// 		transform: scale(0.95);
// 		box-shadow: 0 0 0 0 rgba(50, 93, 166, 0);
// 	}
// `;

export const SideWindowWrapper = styled.div`
  position: fixed;
  top: calc(50% - ${height / 2}px);
  right: calc(50% - ${PAGE_WIDTH / 2}px - ${gap}px - ${width + 30}px);
  /* background-color: white; */
  width: ${width}px;
  /* height: 200px; */
  display: flex;
  flex-direction: column;

  gap: 30px;
`;

export const SideWindowStep = styled.div<{
  $isCurrent?: boolean;
  $isPrev?: boolean;
  $selected?: boolean;
}>`
  /* position: relative; */
  min-width: ${width}px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  padding: 10px 15px;
  border: 1px solid ${colors.bubbleLine};
  background-color: ${({ $isPrev, $isCurrent }) =>
    $isCurrent ? colors.bubbleBg : $isPrev ? 'rgba(40, 56, 86, 0.5)' : 'transparent'};
  color: ${({ $selected }) => ($selected ? colors.white : colors.gray400)};
  cursor: pointer;
  position: relative;
  &:not(&:last-child) {
    &::after {
      content: '';
      width: 1px;
      height: 32px;
      background-color: ${colors.lineDefault};
      position: absolute;
      border: 0;
      /* left: calc(50% - ${130 / 2}px); */
      bottom: -32px;
      left: 50%;
    }
  }
  > svg > path {
    fill: ${({ $isCurrent }) => ($isCurrent ? colors.white : '')};
  }
`;

export const SideWindoeTextWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Circle = styled.div<{ $selected?: boolean }>`
  background-color: ${({ $selected }) => ($selected ? colors.buttonPrimary : colors.gray400)};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 4px solid black;
  border-color: ${({ $selected }) => ($selected ? '#325DA6' : '#31344296')};
  z-index: 2;
  box-sizing: content-box;
  animation: ${({ $selected }) => ($selected ? `1.5s ease pulse infinite` : ``)};
  /* animation-duration: 1.3s;
  animation-iteration-count: infinite; */

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 93, 166, 0.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(50, 93, 166, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(50, 93, 166, 0);
    }
  }
`;

export const StepText = styled.span<{ $isCurrent?: boolean }>`
  position: relative;
  ${typography.body(400)}
  width: 70px;
  font-weight: ${({ $isCurrent }) => ($isCurrent ? 700 : 400)};
  line-height: 1.2;
  color: ${({ $isCurrent }) => ($isCurrent ? colors.white : colors.gray400)};
`;

export const Title = styled.div`
  ${typography.body(700)}
  color: ${colors.gray400};
  text-align: center;
  position: absolute;
  top: -30px;
  left: 40px;
`;
