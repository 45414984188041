import { useQuery } from '@tanstack/react-query';
import apiRequest from '.';
import { MEETING } from './url';
import { useRecoilValue } from 'recoil';
import { meetingRoomIdState, meetingSessionState } from 'states/meetingState';
import { MeetingStateResponse } from './types/response';

const fetcher = (sessionId: string, roomId: number) => {
  return apiRequest
    .get<MeetingStateResponse>(MEETING.GET_METTING_STATE, {
      params: {
        session_id: sessionId,
        room_id: roomId,
      },
    })
    .then(({ data }) => data);
};

/** 현재 미팅의 진행상태를 보여줍니다
 * @param onlyOneExecute 최초 1회만 실행하려고 할 때
 */

export default function useGetMeetingState(enabled: boolean) {
  const sessionId = useRecoilValue(meetingSessionState);
  const roomId = useRecoilValue(meetingRoomIdState);
  return useQuery({
    queryFn: () => fetcher(sessionId, roomId),
    queryKey: [MEETING.GET_METTING_STATE, sessionId, roomId],
    refetchInterval: 1000,
    throwOnError: true,
    enabled: !!sessionId && !!roomId && enabled,
  });
}
