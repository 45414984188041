import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL_MILITARY;

const apiRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiRequest;
