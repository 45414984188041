import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { Description, Title, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import { AnalysisReceivedData } from 'apis/types/response';
import Locales, { ActionResultFieldKey } from 'locale/actionConstant';
import { LanguageType, languageState } from 'states/localeState';
import { StateOfWorkItem } from '../CreateStatementOfWork/CreateStatementOfWork.style';
import Highlight from 'react-highlight';
import { modifySqlQueryForHightLight } from 'utils/utils';
import 'highlight.js/styles/ir-black.css';
type FieldKey = keyof AnalysisReceivedData;

export default function Analysis(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as AnalysisReceivedData;
  const keys = Object.keys(data);

  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const wrappedData = keys.map((field) => ({
    name: ActionResultFieldKey[field]?.[selectLanguage],
    key: field,
    value: data[field as FieldKey],
  }));

  return (
    <Wrapper>
      {wrappedData.map((item, idx) => {
        const Component: Record<FieldKey, any> = {
          ['data_source']: item.value,
          ['statements']:
            item.key === 'statements'
              ? (item.value as string[]).map((sql, sqlIdx) => (
                  <Highlight key={sqlIdx}>{modifySqlQueryForHightLight(sql)}</Highlight>
                ))
              : Locales.WRONG_DATA,
          ['analysis_result']: item.value,
          ['is_ambiguous']: String(item.value),
        };
        return (
          <StateOfWorkItem key={idx}>
            <Title>{item.name}</Title>
            <Description>{Component[item.key as FieldKey]}</Description>
          </StateOfWorkItem>
        );
      })}
    </Wrapper>
  );
}
