import { FilledButton } from 'components/FilledButton/FilledButton';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
`;

export const Text = styled.div`
  ${typography.h1()};
`;

export const SubText = styled.div`
  ${typography.h4()};
  color: ${colors.gray400};
  margin-top: 16px;
  white-space: pre-wrap;
`;

export const RefreshButton = styled(FilledButton)`
  ${typography.h4()};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 12px 30px;
  outline: none;
  gap: 4px;
`;
