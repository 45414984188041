import { ForwardedRef, RefObject, forwardRef, useEffect, useRef, useState } from 'react';

import { ConclusionSummaryType, GroupStageType, MeetingHistoryResponse } from 'apis/types/response';
import { ChatingBoxWrapper } from '../components/ChatingBox/ChatingBox.style';
import AgentChat from '../components/AgentChat/AgentChat';
import Locales, { ActionResultFieldKey } from 'locale/*';
import { LanguageType, languageState } from 'states/localeState';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ConversationCompleteState,
  conversationCompleteState,
} from 'states/conversationCompleteState';
import { checkAllFieldDone, findPrevStepField } from 'utils/utils';
import styled from 'styled-components';
import ConclusionSummary from 'components/ConclusionSummary/ConclusionSummary';

interface ConclusionChatProps {
  data: MeetingHistoryResponse[];
  bodyRef: RefObject<HTMLDivElement>;
  isCurrentStage: boolean;
  groupStageType: GroupStageType;
}

const SHOW_DELAY = 1500;

const ConclusionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ConclusionChat = forwardRef(
  (props: ConclusionChatProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { data, isCurrentStage, groupStageType } = props;
    const [conversationComplete] =
      useRecoilState<ConversationCompleteState>(conversationCompleteState);
    const [wrappedData, setWrappedData] = useState<MeetingHistoryResponse[]>([]);
    const prevFieldKey = findPrevStepField(groupStageType);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const selectLanguage = useRecoilValue<LanguageType>(languageState);
    const prevStageDone = checkAllFieldDone(conversationComplete[prevFieldKey]); // true; //conversationComplete[prevFieldKey].done;
    // console.log('---------- conclusion ----------');
    // console.log('이전 단계의 말이 끝났나 ? ', prevStageDone);
    // console.log(data);

    /** 준비 메세지가 보여진 후 n초 후에 보여지도록 제어함. */
    useEffect(() => {
      if (data.length >= 1 && prevStageDone) {
        setTimeout(() => {
          setWrappedData(data);
        }, SHOW_DELAY);
      }
    }, [data, prevStageDone]);

    useEffect(() => {
      if (wrapperRef.current && isCurrentStage && prevStageDone) {
        wrapperRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, [isCurrentStage, wrapperRef.current, prevStageDone]);

    if (prevStageDone && data.length >= 1) {
      const time = data[0].request_timestamp;
      return (
        <ConclusionWrapper ref={wrapperRef}>
          <ChatingBoxWrapper ref={ref}>
            <AgentChat
              name={Locales.AGENT}
              text={ActionResultFieldKey['conclusion_start_message']?.[selectLanguage]}
              time={time}
            />
          </ChatingBoxWrapper>

          {wrappedData.map((item, idx) => {
            if (item.completed_actions && item.completed_actions[0]?.result_type === 'summary') {
              return (
                <ConclusionSummary
                  name={Locales.AGENT}
                  time={item.request_timestamp}
                  text={item.completed_actions[0].result as ConclusionSummaryType}
                  key={idx}
                />
              );
            } else {
              return (
                <ChatingBoxWrapper ref={ref} key={idx}>
                  {item.completed_actions?.map((action, completeIdx) => {
                    return (
                      <AgentChat
                        isFinal={data.length === idx + 1}
                        key={completeIdx}
                        name={Locales.AGENT}
                        text={action.result as string}
                        time={item.request_timestamp}
                        widthLimit
                      />
                    );
                  })}
                </ChatingBoxWrapper>
              );
            }
          })}
        </ConclusionWrapper>
      );
    } else return null;
  },
);

export default ConclusionChat;
