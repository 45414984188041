import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const DefaultStyledInput = styled.input`
  ${typography.h4()}
  width: 100%;
  height: 52px;
  border: 1px solid ${colors.lineDefault};
  background-color: ${colors.input};
  border-radius: 8px;
  padding: 0 16px;
  color: ${colors.white};

  &::placeholder {
    color: ${colors.placeholder};
  }
  &:hover {
    border-color: ${colors.blue400};
  }

  &:active {
    border-color: ${colors.blue600};
  }

  &:disabled {
    color: ${colors.disabledText};
    background-color: ${colors.disabledBtn};
    border-color: ${colors.lineDefault};
  }
`;

export const DefaultInput = styled(DefaultStyledInput)<{ isError?: boolean }>`
  border-color: ${({ isError }) => (isError ? colors.red400 : '')};
`;
