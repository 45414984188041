import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { Description, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import {
  SowDeliverablesItem,
  SowTableDeliverablesWrapper,
} from '../CreateStatementOfWork/CreateStatementOfWork.style';
export default function OntologyLanguage(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const data = result as string[];
  return (
    <Wrapper>
      <Description>
        {data.map((item, idx) => (
          <SowTableDeliverablesWrapper key={idx}>
            <SowDeliverablesItem>{item}</SowDeliverablesItem>
          </SowTableDeliverablesWrapper>
        ))}
      </Description>
    </Wrapper>
  );
}
