import { useRecoilState, useRecoilValue } from 'recoil';
import { tableModalData } from 'states/tableModalState';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import ActionTable, { ParsedDataType } from './ActionTable';
import { typography } from 'styles/typographies';
import Locales from 'locale/*';
import { useEffect, useState } from 'react';

const ContentsWrapper = styled.div<{ $show: boolean }>`
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  height: 80vh;
  overflow: auto;
  background-color: ${colors.bubbleBg};
  color: ${colors.white};
  gap: 16px;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: all 0.2s ease;
`;

const Title = styled.div`
  ${typography.h3(700)}
  color: ${colors.white};
`;

export default function TableModalContent() {
  const data = useRecoilValue<string>(tableModalData);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
    return () => {
      setShow(false);
    };
  }, []);

  return (
    <ContentsWrapper $show={show}>
      <Title>{Locales.TABLE_MODAL_TITLE}</Title>
      <ActionTable data={data} hasLimit={false} />
    </ContentsWrapper>
  );
}
