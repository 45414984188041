import styled from 'styled-components';
import { colors } from 'styles/colors';

export const HighLightQuotesWrapper = styled.div<{ $completed?: boolean }>`
  /* background-color: black; */
  position: relative;
  ${({ $completed }) => {
    return !$completed
      ? `&::after {
        content: '●';
        color: ${colors.white};
        width: 6px;
        height: 6px;
        border-radius: 50%;
        vertical-align: baseline;
        margin-left: 0.25rem;
        /* animation: 1.5s twinkle infinite; */
      }
    
      @keyframes twinkle {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }`
      : '';
  }}
`;
