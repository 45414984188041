import * as S from './FilledButton.styles';
import { ButtonProps } from 'components/Button/Button';

type CustomProps = {};

type FilledButtonProps = ButtonProps & CustomProps;

export const FilledButton = (props: FilledButtonProps) => {
  return <S.FillStyledButton {...props} />;
};
