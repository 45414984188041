/* eslint-disable @typescript-eslint/no-unused-expressions */

import { QueryCache, QueryClient, QueryClientConfig } from '@tanstack/react-query';

export const queryClientDefaultOptions: QueryClientConfig = {
  queryCache: new QueryCache({}),
  defaultOptions: {
    queries: {},
    mutations: {},
  },
};

export const myQueryClient = new QueryClient(queryClientDefaultOptions);
