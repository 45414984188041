import { SelectCaseandExecute } from 'apis/types/response';
import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { HrBar, Title, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import { CaseDesc, CaseSection, ExecuteCaseItem, CaseTitle } from './SelectCaseAndExecute.style';
import { ActionResultFieldKey } from 'locale/actionConstant';
import Highlight from 'react-highlight';
import 'highlight.js/styles/ir-black.css';

import ActionTable from '../Table/ActionTable';
import { modifySqlQueryForHightLight } from 'utils/utils';

type FieldKey = keyof SelectCaseandExecute;

export default function SelectCaseAndExecute(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const data = result as SelectCaseandExecute[];

  return (
    <Wrapper>
      {data.map((item, idx) => {
        const keys = Object.keys(item);
        const wrappedData = keys.map((key) => {
          const value =
            key === 'query'
              ? modifySqlQueryForHightLight(item[key as FieldKey] as string)
              : key === 'case'
                ? (item[key as FieldKey] as []).join(', ')
                : item[key as FieldKey];
          return {
            name: ActionResultFieldKey[key]?.[selectLanguage],
            key,
            value: value,
          };
        });

        return (
          <ExecuteCaseItem key={idx}>
            {wrappedData.map((listItem, listIdx) => {
              const title = listItem.key === 'case' ? listItem.name + (idx + 1) : listItem.name;
              const desc: Record<string, any> = {
                ['case']: listItem.value,
                ['query']: <Highlight>{listItem.value as string}</Highlight>,
                ['data']: <ActionTable data={listItem.value as string} />,
              };
              return (
                <CaseSection key={listIdx}>
                  <Title $hideDot={listItem.key !== 'case'}>{title}</Title>
                  <CaseDesc>{desc[listItem.key]}</CaseDesc>
                </CaseSection>
              );
            })}

            {data.length - 1 !== idx && <HrBar />}
          </ExecuteCaseItem>
        );
      })}
    </Wrapper>
  );
}
