import { MeetingHistoryResponse } from 'apis/types/response';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const messageKey = 'myMessageKey';
const meetingHistoryMessage = 'meetingHistoryMessageKey';
interface MyMessageState {
  name: string;
  time: string;
  message: string;
}
const { persistAtom } = recoilPersist({
  key: messageKey,
  storage: window.sessionStorage,
});
const { persistAtom: meetingHistoryPersistAtom } = recoilPersist({
  key: messageKey,
  storage: window.sessionStorage,
});

// TODO: 필요없으면 지우기
/** 내가 보낸 메세지 리스트, 필요없으면 지워야 할 수도 있음 */
export const myMessageState = atom({
  key: messageKey,
  default: [] as MyMessageState[],
  effects_UNSTABLE: [persistAtom],
});

export const meetingHistoryMessageState = atom({
  key: meetingHistoryMessage,
  default: [] as MeetingHistoryResponse[],
  effects_UNSTABLE: [meetingHistoryPersistAtom],
});
