import { OntologyKnowledgeMakeCases } from 'apis/types/response';
import { ResultContent } from '../../ResultBody';
import { useRecoilValue } from 'recoil';

export default function OntologyKnowledgeForClarify(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as OntologyKnowledgeMakeCases;
  return <>데이터 검증 중입니다..</>;
}
