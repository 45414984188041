import { useMutation } from '@tanstack/react-query';
import apiRequest from '.';
import { MEETING } from './url';
import { StartMeetingResponse } from './types/response';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { meetingSessionState, meetingRoomIdState } from 'states/meetingState';
import { LanguageType, languageState } from 'states/localeState';

const fetcher = (agenda: string, sessionId: string, selectLanguage: LanguageType) => {
  return apiRequest
    .post<StartMeetingResponse>(MEETING.START_MEETING, null, {
      params: {
        session_id: sessionId,
        agenda,
        lang: selectLanguage,
      },
    })
    .then(({ data }) => data);
};

/** 미팅 시작 호출 */
export default function useStartMeeting() {
  const sessionId = useRecoilValue(meetingSessionState);
  const setmeetingRoomIdState = useSetRecoilState(meetingRoomIdState);
  const selectedLanguage = useRecoilValue<LanguageType>(languageState);
  return useMutation({
    mutationFn: (agenda: string) => fetcher(agenda, sessionId, selectedLanguage),
    onSuccess(data) {
      setmeetingRoomIdState(data.room_id);
    },
    mutationKey: [sessionId],
  });
}
