import LocalizedStrings from 'react-localization';
export * from './actionConstant';
import ko from './ko';
import en from './en';
import { LanguageType } from 'states/localeState';

// console.log('브라우저 언어: ', navigator.language);

const Locales = new LocalizedStrings({
  en: en,
  'en-US': en,
  ko: ko,
  'ko-KR': ko,
});

export const setLocalization = (language: LanguageType) => {
  if (language === 'en') {
    Locales.setLanguage('en-US');
  }
  if (language === 'ko') {
    Locales.setLanguage('ko-KR');
  }
};

export default Locales;
