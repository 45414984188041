import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { meetingSessionState, meetingRoomIdState } from 'states/meetingState';
import apiRequest from '.';
import { MEETING_HISTORY_ACTION_STATE } from 'constants/index';
import { MEETING, ROOM } from './url';
import { RoomStateResponse } from './types/response';

const fetcher = (session_id: string, room_id: number) => {
  return apiRequest
    .post(MEETING.STOP, null, {
      params: {
        session_id,
        room_id,
      },
    })
    .then(({ data }) => data);
};
export default function useStopMeeting() {
  const sessionId = useRecoilValue(meetingSessionState);

  const queryClient = useQueryClient();

  const roomId = useRecoilValue(meetingRoomIdState);
  return useMutation({
    mutationFn: () => fetcher(sessionId, roomId),
    onSuccess: () => {
      const roomInformation = queryClient.getQueryData([
        ROOM.ROOM_INFO,
        sessionId,
        roomId,
      ]) as RoomStateResponse;

      queryClient.setQueryData([ROOM.ROOM_INFO, sessionId, roomId], {
        ...roomInformation,
        state: 'end',
      } as RoomStateResponse);
    },
  });
}
