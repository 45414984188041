import styled from 'styled-components';
import { colors } from 'styles/colors';
import { typography } from 'styles/typographies';

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SliderLabel = styled.div`
  ${typography.caption(400)};
  color: ${colors.white};
  min-width: 33px;
`;

export const IconWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const HorizonBar = styled.div<{ $isActive: boolean; $inActiveColor?: string }>`
  height: 5px;
  border-radius: 50px;
  background-color: ${({ $isActive, $inActiveColor }) =>
    $isActive ? colors.buttonPrimary : $inActiveColor ? $inActiveColor : colors.gray400};
  transition: all 0.2s ease;
  width: 30px;
`;

export const Circle = styled.div<{ $isActive: boolean }>`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: -6px;
  left: ${({ $isActive }) => ($isActive ? '15px' : '0px')};
  background-color: ${colors.white};
  transition: all 0.2s ease;
`;
