import { ResultContent } from '../../ResultBody';
import { ActionResultFieldKey } from 'locale/actionConstant';
import { VoteOptions, Vote as VoteResponse } from 'apis/types/response';
import { useRecoilValue } from 'recoil';
import { LanguageType, languageState } from 'states/localeState';
import { Description, Title, Wrapper } from '../UnderstandIntention/UnderstandIntention.style';
import { StateOfWorkItem } from '../CreateStatementOfWork/CreateStatementOfWork.style';
import {
  TableBody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  TableWrapper,
} from '../Table/ActionTable.style';

type FieldKey = keyof VoteResponse;

type TableKey = keyof VoteOptions;

const OptionTable = ({ datas }: { datas: VoteOptions[] }) => {
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  return (
    <Wrapper>
      {datas.map((data, idx) => {
        const tableKeys = Object.keys(data);
        return (
          <TableWrapper key={idx}>
            <TableThead>
              {tableKeys.map((table, tableIdx) => (
                <TableTh key={tableIdx}>{ActionResultFieldKey[table]?.[selectLanguage]}</TableTh>
              ))}
            </TableThead>
            <TableBody>
              <TableTr>
                {tableKeys.map((table, tableIdx) => {
                  return <TableTd key={tableIdx}>{String(data[table as TableKey])}</TableTd>;
                })}
              </TableTr>
            </TableBody>
          </TableWrapper>
        );
      })}
    </Wrapper>
  );
};

export default function Vote(props: ResultContent) {
  const { action } = props;
  const { result } = action;
  const data = result as VoteResponse;
  const selectLanguage = useRecoilValue<LanguageType>(languageState);
  const keys = Object.keys(data);
  const wrappedData = keys.map((field) => ({
    name: ActionResultFieldKey[field]?.[selectLanguage],
    key: field,
    value: data[field as FieldKey],
  }));

  return (
    <Wrapper>
      {wrappedData.map((item, idx) => {
        return (
          <StateOfWorkItem key={idx}>
            <Title>{item.name}</Title>
            <Description>
              {item.key === 'options' ? (
                <OptionTable datas={item.value as VoteOptions[]} />
              ) : (
                String(item.value)
              )}
            </Description>
          </StateOfWorkItem>
        );
      })}
    </Wrapper>
  );
}
