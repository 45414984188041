import * as S from './Input.styles';
import { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

export const Input = (props: InputProps) => {
  return <S.DefaultInput {...props}></S.DefaultInput>;
};
